import React from 'react';
import { AssessmentRoundMasteryByCapabilities_assessmentRoundMasteryByCapabilities } from '../../graphql';
import LinkButton from '../../components/LinkButton';
import { ChevronLeft as BackIcon } from '@material-ui/icons';
import HeaderWithTitle from '../../components/HeaderWithTitle';
import { makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import CompletionByRegions from './CompletionByRegions';
import CapabilityAndCategory from '../capabilities/CapabilityAndCategory';

interface Props {
  roundName: string;
  data: AssessmentRoundMasteryByCapabilities_assessmentRoundMasteryByCapabilities;
}

const useStyles = makeStyles(theme => ({
  title: {
    color: '#012236', // darker blue
  },
  paperContainer: {
    marginTop: theme.spacing(2),
    width: '90%',
    minWidth: 600,
    maxWidth: 1400,
    padding: theme.spacing(2),
  },
}));

const MasterByCapability: React.FC<Props> = ({ roundName, data }) => {
  const classes = useStyles();

  return (
    <>
      <LinkButton startIcon={<BackIcon />} to="/admin/assessment-rounds">
        Assessment Rounds
      </LinkButton>
      <HeaderWithTitle
        titleClass={classes.title}
        title="Assessment round mastery by category and capability"
        subTitle={roundName}
      />
      <Paper className={classes.paperContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Category / Name</TableCell>
              <TableCell>Mastery levels met</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.categories.map(category => (
              <React.Fragment key={category.category}>
                <TableRow selected>
                  <TableCell>{category.category}</TableCell>
                  <TableCell>
                    <CompletionByRegions regionStats={category.regionStats} />
                  </TableCell>
                </TableRow>
                {category.capabilities.map(capability => (
                  <TableRow key={capability.name}>
                    <TableCell>
                      <CapabilityAndCategory capability={{ name: capability.name, category: category.category }} />
                    </TableCell>
                    <TableCell>
                      <CompletionByRegions regionStats={capability.regionStats} />
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default MasterByCapability;
