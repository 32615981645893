import React from 'react';
import { FormControl, FormHelperText, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import * as _ from 'lodash';

export const SHOW_ALL_REGIONS = 'ALL';

interface Props {
  roles: { region: string }[];
  onChange: (region: string) => void;
}

const useStyles = makeStyles(theme => ({
  regionFilterFormControl: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(),
    minWidth: 300,
  },
  regionFilterHelperText: {
    paddingLeft: theme.spacing(2),
  },
}));

const RegionFilter: React.FC<Props> = ({ roles, onChange }) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.regionFilterFormControl}>
      <InputLabel id="demo-simple-select-label">Region Filter</InputLabel>
      <Select onChange={e => onChange(e.target.value as string)} defaultValue="">
        <MenuItem value={SHOW_ALL_REGIONS} selected>
          All regions
        </MenuItem>
        {_.uniqBy(roles, 'region')
          .map(role => role.region)
          .map(uniqueRegion => (
            <MenuItem key={uniqueRegion} value={uniqueRegion}>
              {uniqueRegion}
            </MenuItem>
          ))}
        <FormHelperText className={classes.regionFilterHelperText}>
          Restrict to only roles from this selected region
        </FormHelperText>
      </Select>
    </FormControl>
  );
};

export default RegionFilter;
