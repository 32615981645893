import { Button, IconButton, InputLabel, makeStyles, MenuItem, Tooltip } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Add as AddIcon, Archive as ArchiveIcon, DragIndicator as DragIcon } from '@material-ui/icons';
import { range } from 'lodash';
import * as React from 'react';
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  DragUpdate,
  Droppable,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import SelectField from '../../components/form/SelectField';
import Input from '../../components/form/TextField';
import { required } from '../../util/validation';
import EditorField from '../../components/form/EditorField';

const useStyles = makeStyles(theme => ({
  selectField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(),
    minWidth: 150,
  },
  standardFields: {
    marginRight: theme.spacing(2),
  },
  capabilityComponents: {
    marginLeft: theme.spacing(4),
  },
  capabilityComponent: {
    display: 'flex',
  },
  componentFieldWrapper: {
    flex: 1,
    '& .MuiInputBase-input': {
      fontSize: 14,
    },
  },
  componentActions: {
    paddingTop: theme.spacing(3),
    textAlign: 'right',
    marginLeft: theme.spacing(2),
  },
  componentsLabel: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  addComponentButtonContainer: {
    textAlign: 'right',
    marginTop: 8,
  },
  moveComponentButton: {
    cursor: 'grab',
    paddingLeft: 0,
    paddingRight: 0,
  },
  fieldRow: {
    display: 'flex',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(4),
    },
    '&:not(:first-child)': {
      marginTop: theme.spacing(),
    },
  },
}));

interface Props {
  existingCapabilityCount: number;
  isNew?: boolean;
}

const CapabilityFormFields = ({ existingCapabilityCount, isNew = false }: Props) => {
  const upperBoundExclusive = isNew ? existingCapabilityCount + 2 : existingCapabilityCount + 1;
  const positionNumbers = range(1, upperBoundExclusive);
  const classes = useStyles();

  const makeOnDragEndFunction =
    (fields: { swap: (indexA: number, indexB: number) => void }) => (result: Readonly<DragUpdate>) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      fields.swap(result.source.index, result.destination.index);
    };

  const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => {
    const styles: React.CSSProperties = {
      userSelect: 'none',
      cursor: 'grab',
      padding: 16,
      paddingTop: 8,
      background: grey[100],
      margin: '16px 0',
      borderRadius: 8,
      ...draggableStyle,
    };

    if (isDragging) {
      styles.background = 'white';
      styles.border = '1px solid rgba(0,0,0,0.2)';
      styles.borderRadius = 8;
      styles.boxShadow = '3px 3px rgba(0,0,0,0.2)';
    }

    return styles;
  };

  return (
    <>
      <div className={classes.standardFields}>
        <div className={classes.fieldRow}>
          <Field
            component={Input}
            name="category"
            label="Category"
            margin="normal"
            fullWidth
            autoFocus
            validate={required('Category is required')}
          />
          <Field
            component={Input}
            name="name"
            label="Name"
            margin="normal"
            fullWidth
            validate={required('Name is required')}
          />
          <Field
            component={SelectField}
            name="order"
            label="Position #"
            formControlProps={{ className: classes.selectField }}
            labelWidth={50}
          >
            {positionNumbers.map(num => (
              <MenuItem key={num} value={num}>
                {num}
              </MenuItem>
            ))}
          </Field>
        </div>
        <div className={classes.fieldRow}>
          <Field
            component={Input}
            name="description"
            label="Description"
            margin="normal"
            fullWidth
            multiline
            parse={value => value}
          />
        </div>
      </div>
      <InputLabel className={classes.componentsLabel} shrink>
        Components (Drag and drop to reorder)
      </InputLabel>

      <FieldArray name="components">
        {({ fields }) => (
          <>
            <DragDropContext onDragEnd={makeOnDragEndFunction(fields)}>
              <Droppable droppableId="droppable">
                {provided => (
                  <div ref={provided.innerRef}>
                    {fields.map((name, index) => (
                      <Draggable key={name} draggableId={name} index={index}>
                        {(provided2, snapshot2) => (
                          <div
                            ref={provided2.innerRef}
                            {...provided2.draggableProps}
                            {...provided2.dragHandleProps}
                            style={getItemStyle(snapshot2.isDragging, provided2.draggableProps.style)}
                          >
                            <div key={name} className={classes.capabilityComponent}>
                              <div className={classes.componentFieldWrapper}>
                                <div>
                                  <div className={classes.fieldRow}>
                                    <Field
                                      component={Input}
                                      name={`${name}.text`}
                                      label="Text"
                                      margin="dense"
                                      multiline
                                      rows={2}
                                      fullWidth
                                      validate={required('Component text is required')}
                                    />
                                  </div>
                                  <div className={classes.fieldRow}>
                                    <Field
                                      component={EditorField}
                                      name={`${name}.emerging`}
                                      label="Emerging definition"
                                      margin="dense"
                                      validate={required('Emerging definition required')}
                                      fullWidth
                                    />
                                    <Field
                                      component={EditorField}
                                      name={`${name}.proficient`}
                                      label="Proficient definition"
                                      margin="dense"
                                      validate={required('Proficient definition required')}
                                      fullWidth
                                    />
                                    <Field
                                      component={EditorField}
                                      name={`${name}.expert`}
                                      label="Expert definition"
                                      margin="dense"
                                      validate={required('Expert definition required')}
                                      fullWidth
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className={classes.componentActions}>
                                {fields && fields.length && fields.length > 1 && (
                                  <Tooltip title="Archive component" aria-label="archive component">
                                    <IconButton onClick={() => fields.remove(index)}>
                                      <ArchiveIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <IconButton className={classes.moveComponentButton} disabled>
                                  <DragIcon />
                                </IconButton>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div className={classes.addComponentButtonContainer}>
              <Tooltip title="Add component" aria-label="add component">
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => fields.push({})}
                  size="small"
                >
                  Component
                </Button>
              </Tooltip>
            </div>
          </>
        )}
      </FieldArray>
    </>
  );
};

export default CapabilityFormFields;
