import * as React from 'react';
import { useQuery } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import NoInvite from '../components/NoInvite';
import { Me, UserRole } from '../graphql';
import Loading from '../modules/pages/Loading';
import { meQuery } from '../modules/users/queries';
import { RouteHelper } from './route-helper';

const RoleRedirect = () => {
  const { data, loading } = useQuery<Me>(meQuery);

  if (loading) {
    return <Loading />;
  }

  const r = new RouteHelper(NoInvite, data && data.me);
  return r.hasAnyRole(UserRole.admin) ? <Redirect to="/admin" /> : <NoInvite />;
};
export default RoleRedirect;
