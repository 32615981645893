import { Link, Paper } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronLeft as BackIcon } from '@material-ui/icons';
import * as React from 'react';
import { useQuery } from '@apollo/client';
import { RouteComponentProps } from 'react-router-dom';
import HeaderWithTitle from '../../../components/HeaderWithTitle';
import LinkButton from '../../../components/LinkButton';
import { ListCapabilities, RoleById, RoleById_roleById } from '../../../graphql';
import { listCapabilitiesQuery } from '../../capabilities/queries';
import Loading from '../../pages/Loading';
import LoadingMask from '../../pages/LoadingMask';
import { roleByIdQuery } from '../queries';
import RoleMasteryLevelsForm from './RoleMasteryLevelsForm';

const useStyles = makeStyles(theme => ({
  introText: {
    margin: theme.spacing(2, 0),
    color: grey[600],
  },
  capabilities: {
    margin: theme.spacing(1, 0),
  },
  title: {
    color: '#012236', // darker blue
  },
  invalidRole: {
    margin: theme.spacing(4, 0),
    height: 200,
    display: 'flex',
    color: grey[600],
    flexDirection: 'column',
    justifyContent: 'center',
    '& div': {
      textAlign: 'center',
    },
  },
}));

interface Params {
  roleId: string;
}

const RoleMasteryLevels = ({ role }: { role: RoleById_roleById }) => {
  const { data: capabilitiesData, loading: capabilitiesLoading } = useQuery<ListCapabilities>(listCapabilitiesQuery);
  const classes = useStyles();

  return (
    <>
      <div className={classes.introText}>
        <span>Select the expected mastery level for each capability component below.</span>
      </div>
      <div className={classes.capabilities}>
        {capabilitiesLoading && <Loading />}
        {capabilitiesData && !capabilitiesLoading && (
          <RoleMasteryLevelsForm
            capabilities={capabilitiesData.capabilities}
            roleId={role.id}
            existingMasteryLevels={role.expectedMasteryLevels}
          />
        )}
      </div>
    </>
  );
};

const RoleMasteryLevelsPage: React.FC<RouteComponentProps<Params>> = ({
  match: {
    params: { roleId },
  },
}) => {
  const { data, loading } = useQuery<RoleById>(roleByIdQuery, {
    variables: { id: roleId },
  });
  const classes = useStyles();

  if (loading) {
    return <LoadingMask />;
  }

  const role = data && data.roleById;

  return (
    <>
      <LinkButton startIcon={<BackIcon />} to="/admin/roles">
        Roles
      </LinkButton>
      <HeaderWithTitle
        titleClass={classes.title}
        title="Expected Mastery Levels for Role"
        subTitle={role ? `${role.region} - ${role.text}` : 'Invalid role'}
      />
      {role ? (
        <RoleMasteryLevels role={role} />
      ) : (
        <Paper className={classes.invalidRole}>
          <div>
            There is no role configured for this role id. Please return to the{' '}
            <Link href="/admin/roles">Roles List</Link> and select a role to edit expected mastery levels for.
          </div>
        </Paper>
      )}
    </>
  );
};

export default RoleMasteryLevelsPage;
