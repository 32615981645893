import {
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Archive as ArchiveIcon, Edit as EditIcon, School as SchoolIcon } from '@material-ui/icons';
import { AlertTitle } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import cx from 'classnames';
import * as _ from 'lodash';
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import NoDataRow from '../../components/common/NoDataRow';
import FormDialog from '../../components/form/FormDialog';
import { showMessage } from '../../components/Toast';
import { ListRolesForAdmin_rolesWithMasteryStats, UpdateRole, UpdateRoleInput } from '../../graphql';
import { archiveRoleMutation, listRolesForAdminQuery, updateRoleMutation } from './queries';
import RoleFormFields from './RoleFormFields';
import RegionFilter, { SHOW_ALL_REGIONS } from '../../components/admin/RegionFilter';

const useStyles = makeStyles(theme => ({
  rolesListContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
  },
  paperContainer: {
    width: '90%',
    minWidth: 600,
    maxWidth: 1400,
    padding: theme.spacing(2),
  },
  actionsCell: {
    minWidth: 200,
    textAlign: 'right',
  },
  masteryLevelsCell: {
    textAlign: 'right',
  },
  incompleteRolesCount: {
    fontWeight: 'bold',
  },
  incompleteRolesAlert: {
    marginBottom: theme.spacing(2),
  },
}));

const useRowStyles = makeStyles(theme => ({
  actionsCell: {
    textAlign: 'right',
    borderRightWidth: 5,
    borderRightStyle: 'solid',
    borderRightColor: 'white',
  },
  actionsMasteryIncomplete: {
    borderRightColor: theme.palette.error.main,
  },
  masteryLevels: {
    textAlign: 'right',
    paddingRight: theme.spacing(4),
    fontWeight: 300,
  },
  masteryLevelsIncomplete: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
  },
  roleTextLabel: {
    color: grey[800],
    fontWeight: 'bold',
    marginTop: 2,
  },
  regionLabel: {
    color: grey[800],
    fontSize: 12,
    fontWeight: 300,
  },
  completedCountHighlighted: {
    fontSize: '1.35rem',
  },
}));

const RoleRow = ({
  role,
  onClickEdit,
  onClickArchive,
}: {
  role: ListRolesForAdmin_rolesWithMasteryStats;
  onClickEdit: () => void;
  onClickArchive: () => void;
}) => {
  const history = useHistory();
  const { masteryStats } = role;
  const classes = useRowStyles();
  return (
    <TableRow>
      <TableCell>
        <ul className="list-unstyled">
          <li className={classes.regionLabel}>{role.region}</li>
          <li className={classes.roleTextLabel}>{role.text}</li>
        </ul>
      </TableCell>
      <TableCell
        className={cx(classes.masteryLevels, {
          [classes.masteryLevelsIncomplete]: !masteryStats.completed,
        })}
      >
        <span
          className={cx({
            [classes.completedCountHighlighted]: !masteryStats.completed,
          })}
        >
          {masteryStats.completedCount}
        </span>{' '}
        / {masteryStats.expectedCount}
      </TableCell>
      <TableCell
        className={cx(classes.actionsCell, {
          [classes.actionsMasteryIncomplete]: !masteryStats.completed,
        })}
      >
        <Tooltip title="Edit" aria-label="edit">
          <IconButton onClick={() => onClickEdit()}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Mastery levels" aria-label="mastery levels">
          <IconButton onClick={() => history.push(`roles/${role.id}/mastery-levels`)}>
            <SchoolIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Archive" aria-label="archive">
          <IconButton onClick={() => onClickArchive()}>
            <ArchiveIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

const RolesList = ({ roles }: { roles: ListRolesForAdmin_rolesWithMasteryStats[] }) => {
  const classes = useStyles();
  const [roleBeingEdited, updateRoleBeingEdited] = useState<ListRolesForAdmin_rolesWithMasteryStats | null>(null);
  const [roleBeingArchived, updateRoleBeingArchived] = useState<ListRolesForAdmin_rolesWithMasteryStats | null>(null);
  const [regionFilter, updateRegionFilter] = useState<string>(SHOW_ALL_REGIONS);
  const incompleteRolesCount = roles.filter(role => !role.masteryStats.completed).length;
  const [updateRole] = useMutation<UpdateRole>(updateRoleMutation, {
    awaitRefetchQueries: true,
    refetchQueries: () => [{ query: listRolesForAdminQuery }],
  });

  const [archiveRole] = useMutation<UpdateRole>(archiveRoleMutation, {
    awaitRefetchQueries: true,
    refetchQueries: () => [{ query: listRolesForAdminQuery }],
  });

  const saveUpdatedRole = async (values: ListRolesForAdmin_rolesWithMasteryStats) => {
    const { id, region, text } = values;
    const toUpdate: UpdateRoleInput = {
      id,
      region,
      text,
    };
    await updateRole({ variables: { role: toUpdate } });
    updateRoleBeingEdited(null);
    showMessage(`Role updated successfully: '${values.text}'`);
  };

  const roleFilter = (roleUnderTest: ListRolesForAdmin_rolesWithMasteryStats) =>
    SHOW_ALL_REGIONS === regionFilter || roleUnderTest.region === regionFilter;

  return (
    <>
      <FormDialog
        title="Update role"
        submitButtonText="Update"
        open={!_.isNil(roleBeingEdited)}
        onSubmit={saveUpdatedRole}
        onCancel={() => updateRoleBeingEdited(null)}
        initialValues={{ ...roleBeingEdited }}
        initialValuesEqual={() => true}
      >
        <RoleFormFields />
      </FormDialog>
      <ConfirmationDialog
        open={!!roleBeingArchived}
        title="Archive Role?"
        onCancel={() => updateRoleBeingArchived(null)}
        onConfirm={async () => {
          await archiveRole({ variables: { roleId: roleBeingArchived!.id } });
          showMessage(`Successfully archived role '${roleBeingArchived!.text}'`);
          updateRoleBeingArchived(null);
        }}
      >
        {roleBeingArchived && (
          <span>
            Are you sure you want to archive the role <strong>{roleBeingArchived.text}</strong> in region{' '}
            <strong>{roleBeingArchived.region}</strong>? This action cannot be undone.
          </span>
        )}
      </ConfirmationDialog>
      <div className={classes.rolesListContainer}>
        <Paper className={classes.paperContainer} elevation={5}>
          {!!incompleteRolesCount && (
            <Alert className={classes.incompleteRolesAlert} severity="error">
              <AlertTitle>Incomplete Roles</AlertTitle>
              {incompleteRolesCount === 1 ? (
                <>
                  There is <span className={classes.incompleteRolesCount}>1</span> role that does not
                </>
              ) : (
                <>
                  There are <span className={classes.incompleteRolesCount}>{incompleteRolesCount}</span> roles that do
                  not
                </>
              )}{' '}
              have all expected mastery levels set. Please see rows below, highlighted in red.
            </Alert>
          )}

          <RegionFilter roles={roles} onChange={updateRegionFilter} />

          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Region / Name</TableCell>
                <TableCell className={classes.masteryLevelsCell}>Mastery Levels</TableCell>
                <TableCell className={classes.actionsCell} />
              </TableRow>
            </TableHead>
            <TableBody>
              {(!roles || roles.length === 0) && (
                <NoDataRow colSpan={3} message="No roles configured. Click the add role button to create a role." />
              )}
              {roles &&
                roles
                  .filter(roleFilter)
                  .map(role => (
                    <RoleRow
                      key={role.id}
                      role={role}
                      onClickEdit={() => updateRoleBeingEdited(role)}
                      onClickArchive={() => updateRoleBeingArchived(role)}
                    />
                  ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </>
  );
};

export default RolesList;
