import { IconButton, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { keyBy } from 'lodash';
import { grey } from '@material-ui/core/colors';
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';
import * as React from 'react';
import { useState } from 'react';
import NoDataRow from '../../components/common/NoDataRow';
import {
  DeleteActivity,
  DeleteActivityVariables,
  ListActivities_activities,
  ListCapabilities_capabilities,
  UpdateActivity,
  UpdateActivityInput,
  UpdateActivityVariables,
} from '../../graphql';
import { Nullable } from '../../util/types';
import FormDialog from '../../components/form/FormDialog';
import ActivityFormFields from './ActivityFormFields';
import { showMessage } from '../../components/Toast';
import { useMutation } from '@apollo/client';
import { deleteActivityMutation, listActivitiesQuery, updateActivityMutation } from './queries';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';

const useStyles = makeStyles(theme => ({
  row: {
    verticalAlign: 'top',
  },
  positionCol: {
    color: grey[800],
    fontWeight: 300,
  },
  componentList: {
    margin: 0,
  },
  actionsCell: {
    width: 130,
  },
}));

interface Props {
  activities: ListActivities_activities[];
  capabilities: ListCapabilities_capabilities[];
}

const ActivityRow = ({
  activity,
  capabilities,
  onClickEdit,
  onClickDelete,
}: {
  activity: ListActivities_activities;
  capabilities: ListCapabilities_capabilities[];
  onClickEdit: () => void;
  onClickDelete: () => void;
}) => {
  const classes = useStyles();
  const activityCapIds = activity.capabilityIds || [];
  const capsById = keyBy(capabilities, 'id');
  const capNames = activityCapIds.map(capId => (capsById[capId] ? capsById[capId].name : null)).filter(name => !!name);
  return (
    <TableRow key={activity.id} className={classes.row}>
      <TableCell>{activity.type}</TableCell>
      <TableCell>{activity.description}</TableCell>
      <TableCell>
        <ul>
          {capNames.map(cap => (
            <li key={cap!}>{cap}</li>
          ))}
        </ul>
      </TableCell>
      <TableCell className={classes.actionsCell}>
        <Tooltip title="Edit learning activity" aria-label="edit">
          <IconButton onClick={() => onClickEdit()}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete learning activity" aria-label="delete">
          <IconButton onClick={() => onClickDelete()}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

const ActivitiesList = ({ activities, capabilities }: Props) => {
  const classes = useStyles();
  const [activityToUpdate, setActivityToUpdate]: any = useState<Nullable<UpdateActivityInput>>(null);
  const [activityToDelete, setActivityToDelete]: any = useState<Nullable<UpdateActivityInput>>(null);
  const [updateActivity] = useMutation<UpdateActivity, UpdateActivityVariables>(updateActivityMutation, {
    awaitRefetchQueries: true,
    refetchQueries: () => [{ query: listActivitiesQuery }],
  });
  const [deleteActivity] = useMutation<DeleteActivity, DeleteActivityVariables>(deleteActivityMutation, {
    awaitRefetchQueries: true,
    refetchQueries: () => [{ query: listActivitiesQuery }],
  });

  const saveUpdatedActivity = async (activity: UpdateActivityInput) => {
    await updateActivity({
      variables: {
        activity: {
          id: activity.id,
          description: activity.description,
          type: activity.type,
          capabilityIds: activity.capabilityIds,
        },
      },
    });
    setActivityToUpdate(null);
    showMessage(`Learning activity successfully updated.`);
  };

  if (!activities) {
    return (
      <div>
        <span>An unexpected error occurred retrieving learning activities.</span>
      </div>
    );
  }

  return (
    <>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Capabilities</TableCell>
            <TableCell className={classes.actionsCell} />
          </TableRow>
        </TableHead>
        <TableBody>
          {activities.length === 0 && <NoDataRow colSpan={4} message="No activities configured" />}
          {activities.map(act => (
            <ActivityRow
              key={act.id}
              activity={act}
              capabilities={capabilities}
              onClickEdit={() => setActivityToUpdate(act)}
              onClickDelete={() => setActivityToDelete(act)}
            />
          ))}
        </TableBody>
      </Table>
      <FormDialog
        onSubmit={saveUpdatedActivity}
        onCancel={() => setActivityToUpdate(null)}
        title="Update learning activity"
        open={!!activityToUpdate}
        initialValues={activityToUpdate && { ...activityToUpdate }}
        initialValuesEqual={() => true}
        allowOverflow={false}
        maxWidth="xl"
      >
        <ActivityFormFields capabilities={capabilities} />
      </FormDialog>
      <ConfirmationDialog
        open={!!activityToDelete}
        title="Delete Learning Activity?"
        onCancel={() => setActivityToDelete(null)}
        onConfirm={async () => {
          await deleteActivity({ variables: { activityId: activityToDelete.id } });
          showMessage(`Successfully deleted learning activity`);
          setActivityToDelete(null);
        }}
      >
        {activityToDelete && (
          <span>Are you sure you want to delete this learning activity? This action cannot be undone.</span>
        )}
      </ConfirmationDialog>
    </>
  );
};

export default ActivitiesList;
