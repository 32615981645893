import {
  FormControlLabel,
  makeStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { ChevronLeft as BackIcon } from '@material-ui/icons';
import React from 'react';
import NoDataRow from '../../components/common/NoDataRow';
import HeaderWithTitle from '../../components/HeaderWithTitle';
import LinkButton from '../../components/LinkButton';
import { AssessmentRoundParticipants_assessmentRoundParticipants_participants } from '../../graphql';
import ParticipantRow from './ParticipantRow';

const useStyles = makeStyles(theme => ({
  title: {
    color: '#012236', // darker blue
  },
  paperContainer: {
    marginTop: theme.spacing(2),
  },
}));

interface Props {
  roundName: string;
  participants: AssessmentRoundParticipants_assessmentRoundParticipants_participants[];
  includeArchived: boolean;
  onToggleArchived: (value: boolean) => void;
}

const ParticipantsList: React.FC<Props> = ({ roundName, participants, includeArchived, onToggleArchived }) => {
  const classes = useStyles();
  return (
    <>
      <LinkButton startIcon={<BackIcon />} to="/admin/assessment-rounds">
        Assessment Rounds
      </LinkButton>
      <HeaderWithTitle
        titleClass={classes.title}
        title="Assessment round participants"
        subTitle={roundName}
        actions={
          <FormControlLabel
            control={
              <Switch
                checked={includeArchived}
                onChange={(_value, checked) => {
                  onToggleArchived(checked);
                }}
                color="primary"
              />
            }
            label="Show archived"
          />
        }
      />
      <Paper className={classes.paperContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Participant</TableCell>
              <TableCell>Manager</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Sent</TableCell>
              <TableCell>Time to complete</TableCell>
              <TableCell width={100}>Mastery levels met</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!participants ||
              (participants.length === 0 && (
                <NoDataRow colSpan={5} message="No participants exist for this assessment round" />
              ))}
            {participants &&
              participants.length > 0 &&
              participants.map((p: AssessmentRoundParticipants_assessmentRoundParticipants_participants) => (
                <ParticipantRow key={p.id} participant={p} roundName={roundName} includeArchived={includeArchived} />
              ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default ParticipantsList;
