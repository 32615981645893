import { Button } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import FormDialog from '../../components/form/FormDialog';
import HeaderWithTitle from '../../components/HeaderWithTitle';
import { showMessage } from '../../components/Toast';
import { CreateRole, ListRolesForAdmin } from '../../graphql';
import Loading from '../pages/Loading';
import { createRoleMutation, listRolesForAdminQuery } from './queries';
import RoleFormFields from './RoleFormFields';
import RolesList from './RolesList';

const RolesPage = () => {
  const [dialogOpen, updateDialogOpen] = useState(false);
  const { data, loading } = useQuery<ListRolesForAdmin>(listRolesForAdminQuery);
  const [createRole] = useMutation<CreateRole>(createRoleMutation, {
    awaitRefetchQueries: true,
    refetchQueries: () => [{ query: listRolesForAdminQuery }],
  });

  const addRole = async (values: any) => {
    await createRole({
      variables: {
        ...values,
      },
    });
    updateDialogOpen(false);
    showMessage(`Role created successfully: '${values.text}'`);
  };

  return (
    <>
      <HeaderWithTitle
        title="Roles"
        actions={
          <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => updateDialogOpen(true)}>
            Add Role
          </Button>
        }
      />
      <FormDialog
        title="Add role"
        submitButtonText="Add"
        open={dialogOpen}
        onSubmit={addRole}
        onCancel={() => updateDialogOpen(false)}
      >
        <RoleFormFields />
      </FormDialog>
      {loading ? <Loading /> : <RolesList roles={data!.rolesWithMasteryStats} />}
    </>
  );
};

export default RolesPage;
