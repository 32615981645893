import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import Time from './Time';

const useStyles = makeStyles(() => ({
  timeComponent: {
    fontSize: '90%',
  },
  compact: {
    display: 'block',
  },
}));

interface Props {
  value: Date | string;
  compact?: boolean;
}

const DateTime = ({ value, compact }: Props) => {
  const classes = useStyles();
  const valueDate = moment(value).toDate();

  return (
    <>
      {moment(value).format('DD/MM/YYYY')}{' '}
      <span className={`${classes.timeComponent} ${compact ? `${classes.compact}` : ''}`}>
        <Time value={valueDate} />
      </span>
    </>
  );
};

export default DateTime;
