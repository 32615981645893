import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import * as React from 'react';
import NoDataRow from '../../components/common/NoDataRow';
import { ListAssessmentRounds_assessmentRounds } from '../../graphql';
import AssessmentRoundRow from './AssessmentRoundRow';

interface Props {
  assessmentRounds: ListAssessmentRounds_assessmentRounds[];
}

const AssessmentRoundsList = ({ assessmentRounds }: Props) => (
  <>
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Invitation dates</TableCell>
          <TableCell>Progress</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {assessmentRounds.length === 0 && <NoDataRow colSpan={4} message="No assessment rounds have been created" />}
        {assessmentRounds.map(round => (
          <AssessmentRoundRow key={round.id} assessmentRound={round} />
        ))}
      </TableBody>
    </Table>
  </>
);

export default AssessmentRoundsList;
