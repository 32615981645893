import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import * as React from 'react';

const useStyles = makeStyles(theme => ({
  noData: {
    textAlign: 'center',
    backgroundColor: grey[200],
    color: grey[700],
  },
}));

interface Props {
  colSpan: number;
  message: string;
}

const NoDataRow = ({ colSpan, message }: Props) => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell className={classes.noData} colSpan={colSpan}>
        {message}
      </TableCell>
    </TableRow>
  );
};

export default NoDataRow;
