import { Button } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import FormDialog from '../../components/form/FormDialog';
import HeaderWithTitle from '../../components/HeaderWithTitle';
import { showMessage } from '../../components/Toast';
import { CreateCapability, CreateCapabilityInput, CreateCapabilityVariables, ListCapabilities } from '../../graphql';
import Loading from '../pages/Loading';
import CapabilitiesList from './CapabilitiesList';
import CapabilityFormFields from './CapabilityFormFields';
import { createCapabilityMutation, listCapabilitiesQuery } from './queries';

const CapabilitiesPage = () => {
  const { data, loading } = useQuery<ListCapabilities>(listCapabilitiesQuery);
  const [createCapability] = useMutation<CreateCapability, CreateCapabilityVariables>(createCapabilityMutation, {
    awaitRefetchQueries: true,
    refetchQueries: () => [{ query: listCapabilitiesQuery }],
  });
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const addCapability = async (capability: CreateCapabilityInput) => {
    await createCapability({
      variables: {
        capability,
      },
    });
    await setAddDialogOpen(false);
    showMessage('Capability successfully added');
  };

  const existingCapabilitiesLength = (data && data.capabilities && data.capabilities.length) || 0;

  return (
    <>
      <HeaderWithTitle
        title="Capabilities"
        actions={
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setAddDialogOpen(true)}
            disabled={loading}
          >
            Add
          </Button>
        }
      />
      {loading ? <Loading /> : <CapabilitiesList capabilities={data ? data.capabilities : []} />}

      <FormDialog
        onSubmit={addCapability}
        onCancel={() => setAddDialogOpen(false)}
        title="Add capability"
        open={addDialogOpen}
        initialValues={{
          order: existingCapabilitiesLength + 1,
          components: [{}],
        }}
        initialValuesEqual={() => true}
        allowOverflow={false}
        maxWidth="xl"
      >
        <CapabilityFormFields existingCapabilityCount={existingCapabilitiesLength} isNew />
      </FormDialog>
    </>
  );
};

export default CapabilitiesPage;
