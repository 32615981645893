import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  container: {
    marginLeft: 16,
  },
  text: {
    color: '#fff',
    fontSize: 14,
  },
}));

const UnsupportedBrowser = () => {
  const classes = useStyles();
  return (
    <div className="welcome">
      <div className={classes.container}>
        <h3>Unsupported browser</h3>
        <div className={classes.text}>
          Sorry, Marketing Masters My Mastery Review does not support Internet Explorer, please re-open your invitation
          link using Chrome.
        </div>
      </div>
    </div>
  );
};

export default UnsupportedBrowser;
