import React from 'react';
import { useQuery } from '@apollo/client';
import { RouteComponentProps } from 'react-router-dom';
import LoadingMask from '../pages/LoadingMask';
import CompletionStatsByManager from './CompletionStatsByManager';
import { assessmentRoundStatisticsByManagersQuery } from './queries';
import { AssessmentRoundStatisticsByManagers } from '../../graphql';

interface Params {
  roundName: string;
}

const AssessmentRoundCompletionStatsByManagerPage: React.FC<RouteComponentProps<Params>> = ({
  match: {
    params: { roundName },
  },
}) => {
  const { data, loading } = useQuery<AssessmentRoundStatisticsByManagers>(assessmentRoundStatisticsByManagersQuery, {
    variables: { roundName },
  });
  if (loading) return <LoadingMask />;

  return data ? (
    <CompletionStatsByManager roundName={roundName} data={data.assessmentRoundStatisticsByManagers} />
  ) : null;
};

export default AssessmentRoundCompletionStatsByManagerPage;
