import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import * as React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(0.5),
    fontSize: 20,
    color: 'inherit',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
  },
  title1: {
    fontWeight: 300,
  },
  title2: {
    fontWeight: 'bold',
  },
  actions: {
    '&>*': {
      marginLeft: 10,
    },
  },
}));

interface Props {
  title: JSX.Element | string;
  subTitle?: JSX.Element | string;
  actions?: JSX.Element;
  titleClass?: string;
}

const HeaderWithTitle: React.FC<Props> = ({ title, subTitle, actions, titleClass }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        {subTitle ? (
          <>
            <Typography className={cx(classes.title1, titleClass)} variant="h5">
              {title}
            </Typography>
            <Typography className={cx(classes.title2, titleClass)} variant="h5">
              {subTitle}
            </Typography>
          </>
        ) : (
          <Typography variant="h5">{title}</Typography>
        )}
      </div>
      {actions && <div className={classes.actions}>{actions}</div>}
    </div>
  );
};

export default HeaderWithTitle;
