import { Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import {
  Assessment as AssessmentIcon,
  ChevronLeft as ChevronLeftIcon,
  LocalLibrary as LocalLibraryIcon,
  PlaylistAddCheck as CapabilitiesIcon,
  SupervisorAccount as Users,
  Work as RolesIcon,
} from '@material-ui/icons';
import cx from 'classnames';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { UserRole } from '../../graphql';
import Authorised from '../Authorised';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    position: 'relative' as 'relative',
    whiteSpace: 'nowrap' as 'nowrap',
    width: 200,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    height: '100%',
  },
  drawerPaperClose: {
    overflowX: 'hidden' as 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  active: {},
  icon: {
    color: 'inherit',
  },
  linkIcon: {
    color: theme.palette.grey.A700,
    '&$active': {
      color: theme.palette.primary.main,
    },
  },
}));

interface Props {
  open: boolean;
  handleDrawerClose?: () => void;
}

const ListItemLink = ListItem as any;
// @ts-ignore
const NavLinkRef = React.forwardRef((props, ref) => <NavLink {...props} />);

const AdminSidebar: React.FC<Props> = ({ handleDrawerClose, open }) => {
  const classes = useStyles();
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: cx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <Authorised roles={UserRole.admin}>
          <ListItemLink
            button
            title="Capabilities"
            component={NavLinkRef}
            to={`/admin/capabilities`}
            activeClassName={classes.active}
            className={classes.linkIcon}
          >
            <ListItemIcon className={classes.icon}>
              <CapabilitiesIcon />
            </ListItemIcon>
            <ListItemText primary="Capabilities" />
          </ListItemLink>
          <ListItemLink
            button
            title="Roles"
            component={NavLinkRef}
            to={`/admin/roles`}
            activeClassName={classes.active}
            className={classes.linkIcon}
          >
            <ListItemIcon className={classes.icon}>
              <RolesIcon />
            </ListItemIcon>
            <ListItemText primary="Roles" />
          </ListItemLink>
          <ListItemLink
            button
            title="Assessment Rounds"
            component={NavLinkRef}
            to={`/admin/assessment-rounds`}
            activeClassName={classes.active}
            className={classes.linkIcon}
          >
            <ListItemIcon className={classes.icon}>
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary="Assessments" />
          </ListItemLink>
          <ListItemLink
            button
            title="Learning Activities"
            component={NavLinkRef}
            to={`/admin/activities`}
            activeClassName={classes.active}
            className={classes.linkIcon}
          >
            <ListItemIcon className={classes.icon}>
              <LocalLibraryIcon />
            </ListItemIcon>
            <ListItemText primary="Assessments" />
          </ListItemLink>
          <ListItemLink
            button
            title="Users"
            component={NavLinkRef}
            to={`/admin/users`}
            activeClassName={classes.active}
            className={classes.linkIcon}
          >
            <ListItemIcon className={classes.icon}>
              <Users />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItemLink>
        </Authorised>
      </List>
    </Drawer>
  );
};

export default AdminSidebar;
