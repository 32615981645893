import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from '@material-ui/core';
import { includes, without } from 'lodash';
import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';

interface FieldOption {
  label: string;
  value: string;
}

interface FieldProps extends HTMLElement {
  options?: FieldOption[] | string[];
  label?: string;
}

interface Props extends FieldRenderProps<string[], FieldProps> {
  options?: (string | FieldOption)[];
  label?: string;
}

const ChecklistField = ({
  input: { onChange, value },
  meta: { touched, error, invalid },
  label,
  options = [],
}: Props) => {
  const fieldOptions: FieldOption[] = options.map((opt: any) =>
    typeof opt === 'string' ? { label: opt, value: opt } : (opt as FieldOption),
  );

  return (
    <FormControl style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
      <FormLabel>{label}</FormLabel>
      <FormGroup>
        {fieldOptions.map(option => (
          <FormControlLabel
            key={option.value}
            control={
              <Checkbox
                checked={includes(value, option.value)}
                onChange={(event, checked) => {
                  if (checked) {
                    onChange([...value, option.value]);
                  } else {
                    onChange(without(value, option.value));
                  }
                }}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
      {touched && error && <FormHelperText error={touched && invalid}>{error}</FormHelperText>}
    </FormControl>
  );
};

export default ChecklistField;
