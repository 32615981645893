import React from 'react';
import { Field } from 'react-final-form';
import { required } from '../../util/validation';
import TextField from '../../components/form/TextField';

const RoleFormFields = () => (
  <>
    <Field
      label="Region"
      fullWidth
      name="region"
      margin="normal"
      autoFocus
      validate={required('Region is required')}
      component={TextField}
    />
    <Field
      label="Name"
      fullWidth
      name="text"
      margin="normal"
      validate={required('Name is required')}
      component={TextField}
    />
  </>
);

export default RoleFormFields;
