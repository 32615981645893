import React from 'react';
import LinkButton from '../../components/LinkButton';
import { ChevronLeft as BackIcon } from '@material-ui/icons';
import HeaderWithTitle from '../../components/HeaderWithTitle';
import { makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { AssessmentRoundStatisticsByManagers_assessmentRoundStatisticsByManagers } from '../../graphql';
import ManagerNameAndEmail from '../../components/admin/ManagerNameAndEmail';
import { toPercentage } from '../../util/util';
import { grey } from '@material-ui/core/colors';
import { sortBy, sumBy } from 'lodash';

interface Props {
  roundName: string;
  data: AssessmentRoundStatisticsByManagers_assessmentRoundStatisticsByManagers;
}

const useStyles = makeStyles(theme => ({
  title: {
    color: '#012236', // darker blue
  },
  paperContainer: {
    marginTop: theme.spacing(2),
    width: '90%',
    minWidth: 600,
    maxWidth: 1400,
    padding: theme.spacing(2),
  },
  roleTextLabel: {
    color: grey[800],
    fontWeight: 'bold',
    marginTop: 2,
  },
  number: {
    fontSize: '1.3rem',
  },
  percent: {
    marginLeft: theme.spacing(2),
  },
  totalRow: {
    backgroundColor: '#fafafa',
  },
}));

const toPercent = (numerator: number, total: number) => toPercentage(total > 0 ? numerator / total : 0);

const CompletionStatsByRole: React.FC<Props> = ({ roundName, data }) => {
  const classes = useStyles();

  const managers = sortBy(data.managers, 'manager.email');

  const notStartedTotal = sumBy(managers, m => m.notStarted);
  const inProgressTotal = sumBy(managers, m => m.inProgress);
  const completeTotal = sumBy(managers, m => m.complete);
  const allTotal = sumBy(managers, m => m.total);

  return (
    <>
      <LinkButton startIcon={<BackIcon />} to="/admin/assessment-rounds">
        Assessment Rounds
      </LinkButton>
      <HeaderWithTitle
        titleClass={classes.title}
        title="Assessment round completion statistics by people leader"
        subTitle={roundName}
      />
      <Paper className={classes.paperContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>People leader</TableCell>
              <TableCell>Not commenced</TableCell>
              <TableCell>In progress</TableCell>
              <TableCell>Complete</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {managers.map(managerStats => {
              const { manager, notStarted, inProgress, complete, total } = managerStats;
              return (
                <TableRow key={manager.email}>
                  <TableCell>
                    <ManagerNameAndEmail manager={manager} />
                  </TableCell>
                  <TableCell>
                    <span className={classes.number}>{notStarted}</span>
                    <Typography variant="caption" className={classes.percent}>
                      {toPercent(notStarted, total)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <span className={classes.number}>{inProgress}</span>
                    <Typography variant="caption" className={classes.percent}>
                      {toPercent(inProgress, total)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <span className={classes.number}>{complete}</span>
                    <Typography variant="caption" className={classes.percent}>
                      {toPercent(complete, total)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <span className={classes.number}>{total}</span>
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow className={classes.totalRow}>
              <TableCell className={classes.roleTextLabel}>TOTAL</TableCell>
              <TableCell>
                <span className={classes.number}>{notStartedTotal}</span>
                <Typography variant="caption" className={classes.percent}>
                  {toPercent(notStartedTotal, allTotal)}
                </Typography>
              </TableCell>
              <TableCell>
                <span className={classes.number}>{inProgressTotal}</span>
                <Typography variant="caption" className={classes.percent}>
                  {toPercent(inProgressTotal, allTotal)}
                </Typography>
              </TableCell>
              <TableCell>
                <span className={classes.number}>{completeTotal}</span>
                <Typography variant="caption" className={classes.percent}>
                  {toPercent(completeTotal, allTotal)}
                </Typography>
              </TableCell>
              <TableCell>
                <span className={classes.number}>{allTotal}</span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default CompletionStatsByRole;
