import { gql } from '@apollo/client';

const roleFragment = () => gql`
  fragment roleFragment on Role {
    id
    region
    text
  }
`;

const roleFragmentWithMasteryLevels = () => gql`
  fragment roleFragmentWithMasteryLevels on Role {
    id
    region
    text
    expectedMasteryLevels {
      componentId
      level
    }
  }
`;

export const roleByIdQuery = gql`
  query RoleById($id: ID!) {
    roleById(id: $id) {
      ...roleFragmentWithMasteryLevels
    }
  }
  ${roleFragmentWithMasteryLevels()}
`;

export const listRolesQuery = gql`
  query ListRoles {
    roles {
      ...roleFragment
    }
  }
  ${roleFragment()}
`;

export const listRolesForAdminQuery = gql`
  query ListRolesForAdmin {
    rolesWithMasteryStats {
      id
      region
      text
      masteryStats {
        completedCount
        expectedCount
        completed
      }
    }
  }
`;

export const updateExpectedMasteryLevelsMutation = gql`
  mutation UpdateExpectedMasteryLevels($roleId: ID!, $expectedMasteryLevels: [ExpectedMasteryLevelInput!]!) {
    updateExpectedMasteryLevels(roleId: $roleId, expectedMasteryLevels: $expectedMasteryLevels) {
      ...roleFragmentWithMasteryLevels
    }
  }
  ${roleFragmentWithMasteryLevels()}
`;

export const createRoleMutation = gql`
  mutation CreateRole($region: String!, $text: String!) {
    createRole(region: $region, text: $text) {
      id
    }
  }
`;

export const updateRoleMutation = gql`
  mutation UpdateRole($role: UpdateRoleInput!) {
    updateRole(role: $role) {
      id
    }
  }
`;

export const archiveRoleMutation = gql`
  mutation ArchiveRole($roleId: ID!) {
    archiveRole(roleId: $roleId) {
      id
    }
  }
`;
