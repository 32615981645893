import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Me, Me_me, UserRole } from '../graphql';
import Loading from '../modules/pages/Loading';
import { meQuery } from '../modules/users/queries';
import { useQuery } from '@apollo/client';
import AdminLayout from '../components/admin/AdminLayout';
import { RouteHelper } from './route-helper';
import Unauthorised from '../modules/pages/Unauthorised';
import AssessmentRoundsPage from '../modules/assessment-rounds/AssessmentRoundsPage';
import UpdateUserPage from '../modules/users/Update';
import List from '../modules/users/List';
import CapabilitiesPage from '../modules/capabilities/CapabilitiesPage';
import RolesPage from '../modules/roles/RolesPage';
import AssessmentRoundParticipantsPage from '../modules/assessment-rounds/AssessmentRoundParticipantsPage';
import AssessmentRoundCompletionStatsByRolePage from '../modules/assessment-rounds/AssessmentRoundCompletionStatsByRolePage';
import AssessmentRoundCompletionStatsByManagerPage from '../modules/assessment-rounds/AssessmentRoundCompletionStatsByManagerPage';
import AssessmentRoundMasteryByComponentPage from '../modules/assessment-rounds/AssessmentRoundMasteryByComponentPage';
import AssessmentRoundMasteryByCapabilityPage from '../modules/assessment-rounds/AssessmentRoundMasteryByCapabilityPage';
import ActivitiesPage from '../modules/activities/ActivitiesPage';
import RoleMasteryLevelsPage from '../modules/roles/mastery-levels/RoleMasteryLevelsPage';

interface Props {
  user: Me_me;
}

const AdminRoutes: React.FC<Props> = () => {
  const { data, loading } = useQuery<Me>(meQuery);

  if (loading) {
    return <Loading />;
  }

  if (!data || !data.me) {
    return <Redirect to="/signin" />;
  }

  const r = new RouteHelper(Unauthorised, data && data.me);

  return (
    <AdminLayout r={r} anyRoles={[UserRole.admin, UserRole.super, UserRole.people_leader]}>
      <Switch>
        <Route path="/admin/users/:userId" component={r.ifHasAnyRole(UpdateUserPage, UserRole.admin)} />
        <Route exact path="/admin/users" component={r.ifHasAnyRole(List, UserRole.admin)} />
        <Route exact path="/admin/capabilities" component={r.ifHasAnyRole(CapabilitiesPage, UserRole.admin)} />
        <Route exact path="/admin/roles" component={r.ifHasAnyRole(RolesPage, UserRole.admin)} />
        <Route
          exact
          path="/admin/assessment-rounds"
          component={r.ifHasAnyRole(AssessmentRoundsPage, UserRole.admin, UserRole.people_leader)}
        />
        <Route
          path="/admin/assessment-rounds/:roundName/participants"
          component={r.ifHasAnyRole(AssessmentRoundParticipantsPage, UserRole.admin, UserRole.people_leader)}
        />
        <Route
          path="/admin/assessment-rounds/:roundName/completion-stats-by-role"
          component={r.ifHasAnyRole(AssessmentRoundCompletionStatsByRolePage, UserRole.admin, UserRole.people_leader)}
        />
        <Route
          path="/admin/assessment-rounds/:roundName/completion-stats-by-people-leader"
          component={r.ifHasAnyRole(AssessmentRoundCompletionStatsByManagerPage, UserRole.admin)}
        />
        <Route
          path="/admin/assessment-rounds/:roundName/mastery-by-component"
          component={r.ifHasAnyRole(AssessmentRoundMasteryByComponentPage, UserRole.admin)}
        />
        <Route
          path="/admin/assessment-rounds/:roundName/mastery-by-capability"
          component={r.ifHasAnyRole(AssessmentRoundMasteryByCapabilityPage, UserRole.admin)}
        />
        <Route exact path="/admin/activities" component={r.ifHasAnyRole(ActivitiesPage, UserRole.admin)} />
        <Route
          path="/admin/roles/:roleId/mastery-levels"
          component={r.ifHasAnyRole(RoleMasteryLevelsPage, UserRole.admin)}
        />
        <Redirect to="/admin/capabilities" />
      </Switch>
    </AdminLayout>
  );
};

export default AdminRoutes;
