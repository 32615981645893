import React from 'react';
import { useQuery } from '@apollo/client';
import { RouteComponentProps } from 'react-router-dom';
import LoadingMask from '../pages/LoadingMask';
import { assessmentRoundStatisticsByRolesQuery } from './queries';
import CompletionStatsByRole from './CompletionStatsByRole';
import { AssessmentRoundStatisticsByRoles } from '../../graphql';

interface Params {
  roundName: string;
}

const AssessmentRoundCompletionStatsByRolePage: React.FC<RouteComponentProps<Params>> = ({
  match: {
    params: { roundName },
  },
}) => {
  const { data, loading } = useQuery<AssessmentRoundStatisticsByRoles>(assessmentRoundStatisticsByRolesQuery, {
    variables: { roundName },
  });
  if (loading) return <LoadingMask />;

  return data ? <CompletionStatsByRole roundName={roundName} data={data.assessmentRoundStatisticsByRoles} /> : null;
};

export default AssessmentRoundCompletionStatsByRolePage;
