import * as React from 'react';
import moment from 'moment';

interface Props {
  value: Date | string;
}

const Time = ({ value }: Props) => <>{moment(value).format('h:mm a')}</>;

export default Time;
