import { Button, CircularProgress } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { useQuery } from '@apollo/client';
import Dropzone from 'react-dropzone';
import HeaderWithTitle from '../../components/HeaderWithTitle';
import { showErrorMessage } from '../../components/Toast';
import { ListAssessmentRounds, UserRole } from '../../graphql';
import { request } from '../../util/http';
import { listAssessmentRoundsQuery } from './queries';
import { Email } from '@material-ui/icons';
import AssessmentRoundsList from './AssessmentRoundsList';
import Loading from '../pages/Loading';
import Authorised from '../../components/Authorised';
import UploadProgressDialog from './UploadProgressDialog';

const AssessmentRoundsPage = () => {
  const [uploading, setUploading] = useState(false);
  const [uploadJobId, setUploadJobId] = useState('');
  const { data, loading, refetch } = useQuery<ListAssessmentRounds>(listAssessmentRoundsQuery);

  const upload = async (files: File[]) => {
    setUploading(true);
    const [file] = files;
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await request('/api/invites/upload', 'POST', formData);
      const result = await response.json();
      console.info(`upload result: ${JSON.stringify(result)}`);
      setUploadJobId(result.id);
    } catch (error) {
      console.error(error);
      showErrorMessage('Failed to send CSV invites');
      setUploading(false);
    }
  };

  const onUploadProgressDialogClosed = () => {
    setUploadJobId('');
    setUploading(false);
    refetch();
  };

  return (
    <>
      <HeaderWithTitle
        title="Assessment Rounds"
        actions={
          <Dropzone
            onDrop={acceptedFiles => upload(acceptedFiles)}
            accept="text/csv, application/vnd.ms-excel"
            className="no-style"
          >
            <Authorised roles={[UserRole.admin]}>
              <Button
                variant="contained"
                color="primary"
                startIcon={uploading ? <CircularProgress size={15} /> : <Email />}
                disabled={uploading}
              >
                Invite participants
              </Button>
            </Authorised>
          </Dropzone>
        }
      />
      {loading ? <Loading /> : <AssessmentRoundsList assessmentRounds={data ? data.assessmentRounds : []} />}
      {!!uploadJobId && <UploadProgressDialog uploadJobId={uploadJobId} onClose={onUploadProgressDialogClosed} />}
    </>
  );
};

export default AssessmentRoundsPage;
