import React from 'react';
import { Typography } from '@material-ui/core';

interface Props {
  manager: { name: string | null; email: string };
}

const ManagerNameAndEmail: React.FC<Props> = ({ manager }) => (
  <ul className="list-unstyled">
    {manager.name && <li>{manager.name}</li>}
    <li>{manager.name ? <Typography variant="caption">{manager.email}</Typography> : manager.email}</li>
  </ul>
);

export default ManagerNameAndEmail;
