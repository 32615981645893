import React, { useState } from 'react';
import LinkButton from '../../components/LinkButton';
import { ChevronLeft as BackIcon } from '@material-ui/icons';
import HeaderWithTitle from '../../components/HeaderWithTitle';
import { makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import RegionFilter, { SHOW_ALL_REGIONS } from '../../components/admin/RegionFilter';
import {
  AssessmentRoundStatisticsByRoles_assessmentRoundStatisticsByRoles,
  AssessmentRoundStatisticsByRoles_assessmentRoundStatisticsByRoles_roles,
} from '../../graphql';
import NoDataRow from '../../components/common/NoDataRow';
import { grey } from '@material-ui/core/colors';
import { toPercentage } from '../../util/util';
import { sumBy } from 'lodash';

interface Props {
  roundName: string;
  data: AssessmentRoundStatisticsByRoles_assessmentRoundStatisticsByRoles;
}

const useStyles = makeStyles(theme => ({
  title: {
    color: '#012236', // darker blue
  },
  paperContainer: {
    marginTop: theme.spacing(2),
    width: '90%',
    minWidth: 600,
    maxWidth: 1400,
    padding: theme.spacing(2),
  },
  roleTextLabel: {
    color: grey[800],
    fontWeight: 'bold',
    marginTop: 2,
  },
  archivedLabel: {
    fontSize: 10,
    verticalAlign: 'middle',
  },
  regionLabel: {
    color: grey[800],
    fontSize: 12,
    fontWeight: 300,
  },
  number: {
    fontSize: '1.3rem',
  },
  percent: {
    marginLeft: theme.spacing(2),
  },
  totalRow: {
    backgroundColor: '#fafafa',
  },
}));

const toPercent = (numerator: number, total: number) => toPercentage(total > 0 ? numerator / total : 0);

const CompletionStatsByRole: React.FC<Props> = ({ roundName, data }) => {
  const classes = useStyles();
  const [regionFilter, updateRegionFilter] = useState<string>(SHOW_ALL_REGIONS);

  const roleFilter = (roleUnderTest: AssessmentRoundStatisticsByRoles_assessmentRoundStatisticsByRoles_roles) =>
    SHOW_ALL_REGIONS === regionFilter || roleUnderTest.region === regionFilter;

  const roles = data.roles.filter(roleFilter);

  const inProgressTotal = sumBy(roles, r => r.inProgress);
  const completeTotal = sumBy(roles, r => r.complete);
  const allTotal = inProgressTotal + completeTotal;

  return (
    <>
      <LinkButton startIcon={<BackIcon />} to="/admin/assessment-rounds">
        Assessment Rounds
      </LinkButton>
      <HeaderWithTitle
        titleClass={classes.title}
        title="Assessment round completion statistics by role/region"
        subTitle={roundName}
      />
      <Paper className={classes.paperContainer}>
        <RegionFilter roles={data.roles} onChange={updateRegionFilter} />
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Region / Name</TableCell>
              <TableCell>In progress</TableCell>
              <TableCell>Complete</TableCell>
              <TableCell>
                Total <Typography variant="caption">(sum of in progress and complete)</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roles.length === 0 && (
              <NoDataRow colSpan={3} message="No roles configured. Click the add role button to create a role." />
            )}
            {roles.map((role, index) => {
              const { inProgress, complete } = role;
              const total = inProgress + complete;
              return (
                <TableRow key={`${role.role}-${index}`}>
                  <TableCell>
                    <ul className="list-unstyled">
                      <li className={classes.regionLabel}>{role.region}</li>
                      <li className={classes.roleTextLabel}>
                        {role.role} <span className={classes.archivedLabel}>{role.archived ? '(Archived)' : ''}</span>
                      </li>
                    </ul>
                  </TableCell>
                  <TableCell>
                    <span className={classes.number}>{inProgress}</span>
                    <Typography variant="caption" className={classes.percent}>
                      {toPercent(inProgress, total)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <span className={classes.number}>{complete}</span>
                    <Typography variant="caption" className={classes.percent}>
                      {toPercent(complete, total)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <span className={classes.number}>{total}</span>
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow className={classes.totalRow}>
              <TableCell className={classes.roleTextLabel}>TOTAL</TableCell>
              <TableCell>
                <span className={classes.number}>{inProgressTotal}</span>
                <Typography variant="caption" className={classes.percent}>
                  {toPercent(inProgressTotal, allTotal)}
                </Typography>
              </TableCell>
              <TableCell>
                <span className={classes.number}>{completeTotal}</span>
                <Typography variant="caption" className={classes.percent}>
                  {toPercent(completeTotal, allTotal)}
                </Typography>
              </TableCell>
              <TableCell>
                <span className={classes.number}>{allTotal}</span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default CompletionStatsByRole;
