import React, { useState } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogActions, Button, CircularProgress } from '@material-ui/core';
import DialogTitleWithClose from './DialogTitleWithClose';

type Props = {
  open: boolean;
  title: string;
  onCancel: () => void;
  onConfirm: () => Promise<void>;
};

const ConfirmationDialog: React.FC<Props> = ({ open, title, onCancel, onConfirm, children }) => {
  const [submitting, updateSubmitting] = useState(false);
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitleWithClose title={title} onClose={onCancel} />
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          color="primary"
          disabled={submitting}
          onClick={async () => {
            updateSubmitting(true);
            try {
              await onConfirm();
            } finally {
              updateSubmitting(false);
            }
          }}
        >
          {submitting ? <CircularProgress size={15} /> : 'OK'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
