import React from 'react';
import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

interface Props {
  capability: {
    name: string;
    category: string;
  };
}

const useStyles = makeStyles(theme => ({
  categoryLabel: {
    color: grey[800],
    fontSize: 12,
    fontWeight: 300,
  },
  nameLabel: {
    color: grey[800],
    fontWeight: 'bold',
    marginTop: 2,
  },
}));

const CapabilityAndCategory: React.FC<Props> = ({ capability }) => {
  const classes = useStyles();
  return (
    <ul className="list-unstyled">
      <li className={classes.categoryLabel}>{capability.category}</li>
      <li className={classes.nameLabel}>{capability.name}</li>
    </ul>
  );
};

export default CapabilityAndCategory;
