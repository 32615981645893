import React from 'react';
import { useQuery } from '@apollo/client';
import { RouteComponentProps } from 'react-router-dom';
import LoadingMask from '../pages/LoadingMask';
import { assessmentRoundMasteryByCapabilitiesQuery } from './queries';
import { AssessmentRoundMasteryByCapabilities } from '../../graphql';
import MasteryByCapability from './MasteryByCapability';

interface Params {
  roundName: string;
}

const AssessmentRoundMasteryByCapabilityPage: React.FC<RouteComponentProps<Params>> = ({
  match: {
    params: { roundName },
  },
}) => {
  const { data, loading } = useQuery<AssessmentRoundMasteryByCapabilities>(assessmentRoundMasteryByCapabilitiesQuery, {
    variables: { roundName },
  });
  if (loading) return <LoadingMask />;

  return data ? <MasteryByCapability roundName={roundName} data={data.assessmentRoundMasteryByCapabilities} /> : null;
};

export default AssessmentRoundMasteryByCapabilityPage;
