import React from "react";
import {ListCapabilities_capabilities} from "../graphql";
import {Typography} from "@material-ui/core";
import {HelpOutline as HelpIcon} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    categoryHeading: {
        fontWeight: 'bold',
    },
    stepTextLighter: {
        color: '#83A2B8',
    },
    helpIcon: {
        verticalAlign: 'middle',
        color: theme.palette.primary.main,
        fontSize: '2rem',
    },
    capabilityDescription: {
        marginBottom: theme.spacing(2),
        lineHeight: 1.4,
    }
}));


const CapabilityHeader: React.FC<{ capability: ListCapabilities_capabilities, step: number, steps: number }> = ({capability, step, steps}) => {
    const classes = useStyles();
    return (
        <>
            <h1 className={classes.categoryHeading}>{capability.category}</h1>
            <h5>
                <span className={classes.stepTextLighter}>Step</span> {step}{' '}
                <span className={classes.stepTextLighter}>of</span> {steps}
            </h5>
            <h2>{`${capability.order}. ${capability.name}`}</h2>
            {capability.description && (
                <Typography variant="subtitle2" className={classes.capabilityDescription}>
                    {capability.description}
                </Typography>
            )}
            <h6>
                Click on the {<HelpIcon className={classes.helpIcon}/>} button below each statement to review its
                mastery
                level definitions
            </h6>
        </>
    )
}

export default CapabilityHeader
