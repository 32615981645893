import { without, startCase } from 'lodash';
import * as React from 'react';
import { Field } from 'react-final-form';
import ChecklistField from '../../components/form/ChecklistField';
import { UserRole } from '../../graphql';
import { minLength } from '../../util/validation';

export const roleAsText = (role: string) => startCase(role);

const RolesField = () => (
  <Field
    label="Roles"
    fullWidth
    name="roles"
    margin="normal"
    options={without(Object.keys(UserRole), 'super').map(role => ({
      label: roleAsText(role),
      value: role,
    }))}
    validate={minLength('At least one role must be selected', 1)}
    component={ChecklistField}
  />
);

export default RolesField;
