import { Grid, makeStyles, RadioGroup } from '@material-ui/core';
import React from 'react';
import CapabilityRatingResponse from './CapabilityRatingResponse';
import levelsOfMasteryInfo from './levelsOfMasteryInfo';

const useStyles = makeStyles(theme => ({
  radioGroup: {
    justifyContent: 'space-evenly',
  },
  spaced: {
    marginTop: theme.spacing(2),
  },
}));

interface Props {
  fieldName: string;
  radioTextClass?: string;
}

const RatingField: React.FC<Props> = ({ fieldName, radioTextClass }) => {
  const classes = useStyles();
  return (
    <RadioGroup name={fieldName} row className={classes.radioGroup}>
      <Grid container>
        <CapabilityRatingResponse
          info={levelsOfMasteryInfo.EMERGING}
          text="Emerging"
          value="1"
          fieldName={fieldName}
          radioTextClass={radioTextClass}
        />
        <CapabilityRatingResponse
          info={levelsOfMasteryInfo.PROFICIENT}
          text="Proficient"
          value="2"
          fieldName={fieldName}
          radioTextClass={radioTextClass}
        />
        <CapabilityRatingResponse
          info={levelsOfMasteryInfo.EXPERT}
          text="Expert"
          value="3"
          fieldName={fieldName}
          radioTextClass={radioTextClass}
        />
        <CapabilityRatingResponse
          info={levelsOfMasteryInfo.VISIONARY}
          text="Visionary"
          value="4"
          fieldName={fieldName}
          radioTextClass={radioTextClass}
        />
      </Grid>
    </RadioGroup>
  );
};

export default RatingField;
