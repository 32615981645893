import { gql } from '@apollo/client';

const assessmentStatusStatisticsFragment = gql`
  fragment AssessmentStatusStatisticsFragment on AssessmentStatusStatistics {
    status
    total
    percentageInRound
  }
`;

export const listAssessmentRoundsQuery = gql`
  query ListAssessmentRounds {
    assessmentRounds {
      id
      createdAt
      lastInvitedAt
    }
  }
`;

export const assessmentRoundStatisticsQuery = gql`
  query AssessmentRoundStatistics($roundName: ID!) {
    assessmentRoundStatistics(roundName: $roundName) {
      total
      roundName
      invited {
        ...AssessmentStatusStatisticsFragment
      }
      inProgress {
        ...AssessmentStatusStatisticsFragment
      }
      complete {
        ...AssessmentStatusStatisticsFragment
      }
    }
  }
  ${assessmentStatusStatisticsFragment}
`;

export const assessmentRoundStatisticsByRolesQuery = gql`
  query AssessmentRoundStatisticsByRoles($roundName: ID!) {
    assessmentRoundStatisticsByRoles(roundName: $roundName) {
      roundName
      roles {
        role
        region
        inProgress
        complete
        archived
      }
    }
  }
`;

export const assessmentRoundStatisticsByManagersQuery = gql`
  query AssessmentRoundStatisticsByManagers($roundName: ID!) {
    assessmentRoundStatisticsByManagers(roundName: $roundName) {
      roundName
      managers {
        manager {
          email
          name
        }
        notStarted
        inProgress
        complete
        total
      }
    }
  }
`;

export const assessmentRoundMasteryByComponentsQuery = gql`
  query AssessmentRoundMasteryByComponents($roundName: ID!) {
    assessmentRoundMasteryByComponents(roundName: $roundName) {
      roundName
      capabilities {
        category
        name
        components {
          text
          regionStats {
            regionName
            masteryLevelMetCount
            completedAssessments
          }
        }
      }
    }
  }
`;

export const assessmentRoundMasteryByCapabilitiesQuery = gql`
  query AssessmentRoundMasteryByCapabilities($roundName: ID!) {
    assessmentRoundMasteryByCapabilities(roundName: $roundName) {
      roundName
      categories {
        category
        regionStats {
          regionName
          masteryLevelMetCount
          completedAssessments
        }
        capabilities {
          name
          regionStats {
            regionName
            masteryLevelMetCount
            completedAssessments
          }
        }
      }
    }
  }
`;

export const assessmentRoundParticipantsQuery = gql`
  query AssessmentRoundParticipants($roundName: ID!, $includeArchived: Boolean) {
    assessmentRoundParticipants(roundName: $roundName, includeArchived: $includeArchived) {
      participants {
        id
        firstName
        lastName
        email
        status
        inviteSent
        startedAt
        completedAt
        manager {
          email
          name
        }
        masteryLevelsMetFraction
        report
      }
    }
  }
`;

export const unlockAssessmentGql = gql`
  mutation UnlockAssessment($id: ID!) {
    unlockAssessment(id: $id) {
      id
      firstName
      lastName
      email
      status
      inviteSent
      startedAt
      completedAt
      manager {
        email
        name
      }
      masteryLevelsMetFraction
      report
    }
  }
`;

export const archiveAssessmentMutation = gql`
  mutation ArchiveAssessment($id: ID!) {
    archiveAssessment(id: $id) {
      id
      firstName
      lastName
      email
      status
      inviteSent
      startedAt
      completedAt
      manager {
        email
        name
      }
      masteryLevelsMetFraction
      report
    }
  }
`;

export const reInviteGql = gql`
  mutation ReInvite($id: ID!) {
    reInvite(id: $id)
  }
`;
