import { createTheme } from '@material-ui/core/styles';
export const THEME_PRIMARY_COLOUR = '#00A1E0';

export default createTheme({
  palette: {
    primary: {
      main: THEME_PRIMARY_COLOUR,
      contrastText: '#fff',
    },
    secondary: {
      main: '#004062',
    },
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 10,
      },
    },
  },
});
