import { Button, makeStyles, Theme } from '@material-ui/core';
import * as qs from 'query-string';
import * as React from 'react';
import { useEffect } from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Form from '../../components/Form';
import Input from '../../components/form/TextField';
import { showErrorMessage } from '../../components/Toast';
import { requestJSON } from '../../util/http';
import AccountPage from './AccountPage';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { isArray } from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
  submit: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
}));

const logIn =
  (client: ApolloClient<object>) =>
  async ({ username, password }: { username: string; password: string }) => {
    await requestJSON('/auth/signin/local', 'post', {
      username,
      password,
    });

    await client.reFetchObservableQueries();
    window.location.href = '/admin/assessment-rounds';
  };

const SignIn: React.FC = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const history = useHistory();
  const client = useApolloClient();

  useEffect(() => {
    const { error } = qs.parse(search);
    if (error) {
      const msg = isArray(error) ? error : [error];
      showErrorMessage(msg.join(' '));
      history.replace(`/signin`);
    }
  }, [history, search]);

  return (
    <AccountPage
      title="Sign In"
      links={
        <React.Fragment>
          <Link to="/reset">Forgot password?</Link>
        </React.Fragment>
      }
    >
      <Form onSubmit={logIn(client)}>
        {({ handleSubmit, submitting }: FormRenderProps) => (
          <form onSubmit={handleSubmit}>
            <Field label="Email" fullWidth name="username" margin="normal" component={Input} />

            <Field label="Password" fullWidth type="password" name="password" margin="normal" component={Input} />

            <Button type="submit" color="primary" variant="contained" className={classes.submit} disabled={submitting}>
              Sign in
            </Button>
          </form>
        )}
      </Form>
    </AccountPage>
  );
};

export default SignIn;
