import { gql } from '@apollo/client';

export const listActivitiesQuery = gql`
  query ListActivities {
    activities {
      id
      description
      type
      capabilityIds
      createdAt
    }
  }
`;

export const createActivityMutation = gql`
  mutation CreateActivity($activity: CreateActivityInput!) {
    createActivity(activity: $activity)
  }
`;

export const updateActivityMutation = gql`
  mutation UpdateActivity($activity: UpdateActivityInput!) {
    updateActivity(activity: $activity)
  }
`;

export const deleteActivityMutation = gql`
  mutation DeleteActivity($activityId: ID!) {
    deleteActivity(activityId: $activityId)
  }
`;
