import { Button, makeStyles, Theme } from '@material-ui/core';
import * as React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { Link } from 'react-router-dom';
import Form from '../../components/Form';
import Input from '../../components/form/TextField';
import { ResetPassword, ResetPasswordVariables } from '../../graphql';
import { required } from '../../util/validation';
import AccountPage from './AccountPage';
import { gql, useMutation } from '@apollo/client';

const useStyles = makeStyles((theme: Theme) => ({
  submit: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
  google: {
    width: '100%',
    marginTop: theme.spacing(4),
    background: theme.palette.common.white,
  },
}));

const resetPasswordMutation = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;

const SignIn: React.FC = () => {
  const classes = useStyles();
  const [resetPassword] = useMutation<ResetPassword, ResetPasswordVariables>(resetPasswordMutation);

  return (
    <AccountPage
      title="Reset password"
      links={
        <React.Fragment>
          <Link to="/signin">Signing in?</Link>
        </React.Fragment>
      }
    >
      <Form<{ email: string }>
        onSubmit={({ email }: { email: string }) => resetPassword({ variables: { email } })}
        successMessage="Password reset email has been sent"
      >
        {({ handleSubmit, submitting }: FormRenderProps) => (
          <form onSubmit={handleSubmit}>
            <Field
              label="Email"
              fullWidth
              name="email"
              margin="normal"
              validate={required('Email address is required')}
              component={Input}
            />

            <Button type="submit" color="primary" variant="contained" className={classes.submit} disabled={submitting}>
              Reset password
            </Button>
          </form>
        )}
      </Form>
    </AccountPage>
  );
};

export default SignIn;
