import { makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Email as InviteSentIcon, Minimize as NotStartedIcon, Archive as ArchiveIcon } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import React, { useState } from 'react';
import DateTime from '../../components/common/DateTime';
import {
  ArchiveAssessment,
  ArchiveAssessmentVariables,
  AssessmentRoundParticipants_assessmentRoundParticipants_participants,
  ReInvite,
  Status,
  UnlockAssessment,
  UnlockAssessmentVariables,
} from '../../graphql';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import UnlockIcon from '@material-ui/icons/LockOpen';
import ManagerNameAndEmail from '../../components/admin/ManagerNameAndEmail';
import { toPercentage } from '../../util/util';
import cx from 'classnames';
import { useMutation } from '@apollo/client';
import { showMessage } from '../../components/Toast';
import {
  archiveAssessmentMutation,
  assessmentRoundParticipantsQuery,
  reInviteGql,
  unlockAssessmentGql,
} from './queries';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';

const deriveAlertSeverity = (status: Status) => {
  switch (status) {
    case 'ARCHIVED':
      return 'error';
    case 'IN_PROGRESS':
      return 'info';
    case 'INVITED':
      return 'warning';
    default:
      return 'success';
  }
};

const deriveAlertIcon = (status: Status) => {
  switch (status) {
    case 'INVITED':
      return <InviteSentIcon />;
    default:
      return '';
  }
};

const activeUser = (status: Status) => status !== 'ARCHIVED';

const canUnlockAssessment = (status: Status) => activeUser(status) && status === 'COMPLETE';

const canReInvite = (status: Status) => activeUser(status) && status !== 'COMPLETE';
const canArchive = (status: Status) => activeUser(status);

const useStyles = makeStyles(() => ({
  notStartedIcon: {
    color: grey[300],
  },
  multiValueCol: {
    display: 'flex',
  },
  topMargin: {
    marginTop: 6,
  },
  multiValueColLeft: {
    width: 100,
  },
}));

interface Props {
  participant: AssessmentRoundParticipants_assessmentRoundParticipants_participants;
  roundName: string;
  includeArchived?: boolean;
}

const ParticipantRow: React.FC<Props> = ({ participant, roundName, includeArchived }) => {
  const classes = useStyles();
  const [localParticipant, setLocalParticipant] = useState(participant);
  const [
    participantToArchive,
    setParticipantToArchive,
  ] = useState<AssessmentRoundParticipants_assessmentRoundParticipants_participants | null>(null);
  const { manager } = localParticipant;
  const [unlockAssessment] = useMutation<UnlockAssessment, UnlockAssessmentVariables>(unlockAssessmentGql);
  const [archiveAssessment] = useMutation<ArchiveAssessment, ArchiveAssessmentVariables>(archiveAssessmentMutation, {
    awaitRefetchQueries: true,
    refetchQueries: () => [{ query: assessmentRoundParticipantsQuery, variables: { roundName, includeArchived } }],
  });
  const [reinvite] = useMutation<ReInvite, UnlockAssessmentVariables>(reInviteGql);

  const handleUnlock = (id: string) => {
    unlockAssessment({ variables: { id } }).then(result => {
      if (result && result.data) {
        const updatedParticipant = result.data.unlockAssessment;
        setLocalParticipant(updatedParticipant);
        showMessage('Assessment was unlocked');
      }
    });
  };

  const handleArchive = async (id: string) => {
    await archiveAssessment({ variables: { id } }).then(result => {
      if (result && result.data) {
        const updatedParticipant = result.data.archiveAssessment;
        setLocalParticipant(updatedParticipant);
        showMessage('Assessment has been archived');
      }
    });
  };

  const handleReinvite = (id: string) => {
    reinvite({ variables: { id } }).then(result => {
      if (result) {
        showMessage('An invitation email has been resent');
      }
    });
  };

  let timeToComplete;
  if (localParticipant.completedAt) {
    const startedAt = moment(localParticipant.startedAt);
    const completedAt = moment(localParticipant.completedAt);
    const duration = moment.duration(completedAt.diff(startedAt));
    timeToComplete = `${duration.asHours().toFixed(2)} hours`;
  }

  return (
    <>
      <TableRow key={localParticipant.id}>
        <TableCell>
          <ul className="list-unstyled">
            <li>{`${localParticipant.lastName}, ${localParticipant.firstName}`}</li>
            <li>
              <Typography variant="caption">{localParticipant.email}</Typography>
            </li>
          </ul>
        </TableCell>
        <TableCell>
          <ManagerNameAndEmail manager={manager} />
        </TableCell>
        <TableCell>
          <Alert
            severity={deriveAlertSeverity(localParticipant.status)}
            icon={deriveAlertIcon(localParticipant.status)}
          >
            {localParticipant.status}
          </Alert>
        </TableCell>
        <TableCell>
          <DateTime value={localParticipant.inviteSent} compact={true} />
        </TableCell>
        <TableCell>
          {!localParticipant.startedAt && <NotStartedIcon className={classes.notStartedIcon} />}
          {localParticipant.startedAt && (
            <ul className="list-unstyled">
              <li className={classes.multiValueCol}>
                <div className={classes.multiValueColLeft}>Started</div>
                <div>
                  <DateTime value={localParticipant.startedAt} />
                </div>
              </li>
              {localParticipant.completedAt && (
                <>
                  <li className={cx(classes.multiValueCol, classes.topMargin)}>
                    <div className={classes.multiValueColLeft}>Completed</div>
                    <div>
                      <DateTime value={localParticipant.completedAt} />
                    </div>
                  </li>
                  <li className={cx(classes.multiValueCol, classes.topMargin)}>
                    <div className={classes.multiValueColLeft}>Duration</div>
                    <div>{timeToComplete}</div>
                  </li>
                </>
              )}
            </ul>
          )}
        </TableCell>
        <TableCell>
          {localParticipant.status === 'COMPLETE' &&
            localParticipant.masteryLevelsMetFraction &&
            toPercentage(localParticipant.masteryLevelsMetFraction)}
        </TableCell>
        <TableCell>
          {localParticipant.report && (
            <a
              target="_blank"
              rel="noreferrer"
              title={'Download Report'}
              href={`/api/assessments/${encodeURIComponent(localParticipant.report)}/download`}
            >
              <PictureAsPdfIcon />
            </a>
          )}
          {/*todo: update links to be buttons*/}
          {canUnlockAssessment(localParticipant.status) && (
            <a
              target="_blank"
              rel="noreferrer"
              title={'Unlock'}
              onClick={() => handleUnlock(localParticipant.id)}
              href={undefined}
            >
              <UnlockIcon cursor={'pointer'} />
            </a>
          )}
          {canReInvite(localParticipant.status) && (
            <a
              target="_blank"
              rel="noreferrer"
              title={'Resend Invitation'}
              onClick={() => handleReinvite(localParticipant.id)}
              href={undefined}
            >
              <InviteSentIcon cursor={'pointer'} />
            </a>
          )}
          {canArchive(localParticipant.status) && (
            <a
              target="_blank"
              rel="noreferrer"
              title={'Archive participant'}
              onClick={() => setParticipantToArchive(localParticipant)}
              href={undefined}
            >
              <ArchiveIcon cursor={'pointer'} />
            </a>
          )}
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        open={!!participantToArchive}
        title="Archive Assessment?"
        onCancel={() => {
          setParticipantToArchive(null);
        }}
        onConfirm={async () => {
          await handleArchive(localParticipant.id);
          setParticipantToArchive(null);
        }}
      >
        <span>
          Are you sure you want to archive{' '}
          {participantToArchive ? (
            <strong>{`${localParticipant.firstName} ${localParticipant.lastName}`}</strong>
          ) : (
            'this assessment'
          )}
          ?
        </span>
      </ConfirmationDialog>
    </>
  );
};

export default ParticipantRow;
