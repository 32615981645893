import React from 'react';
import { Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import { Field } from 'react-final-form';
import Input from './form/TextField';
import { FINAL_FORM_FIELD_PREFIX_COMMENT } from './Capabilities';

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: 24,
  },
  cardHeader: {
    paddingBottom: 0,
  },
  cardHeaderTitle: {
    textAlign: 'left',
  },
  text: {
    fontSize: 14,
    color: '#83A2B8',
    textAlign: 'left',
  },
}));

const CapabilityComment: React.FC<{ capabilityId: string }> = ({ capabilityId }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent>
        <Field
          label="Comment"
          fullWidth
          name={`${FINAL_FORM_FIELD_PREFIX_COMMENT}${capabilityId}`}
          margin="normal"
          minRows={4}
          maxRows={4}
          id="standard-multiline-static"
          component={Input}
          multiline
          inputProps={{ maxLength: 200 }}
        />
        <Typography color={'textSecondary'} className={classes.text}>
          A comment added here will be included in your report. Comment is optional and limited to 200 characters.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CapabilityComment;
