import React, { useState } from 'react';
import { Button, Card, CardActions, CardContent, CardHeader, makeStyles, Tooltip } from '@material-ui/core';
import { HelpOutline as HelpIcon } from '@material-ui/icons';
import { ListCapabilities_capabilities_components } from '../graphql';
import { FINAL_FORM_FIELD_PREFIX_RATING } from './Capabilities';
import LevelsOfMasteryDialog from './LevelsOfMasterDialog';
import RatingField from './RatingField';

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: 24,
  },
  cardHeader: {
    paddingBottom: 0,
  },
  cardHeaderTitle: {
    textAlign: 'left',
  },
  cardActionsContainer: {
    justifyContent: 'flex-end',
    paddingTop: 0,
  },
  text: {
    fontSize: 14,
    color: '#83A2B8',
  },
  helpIcon: {
    color: theme.palette.secondary.main,
  },
  viewMasteryLevelsButton: {
    fontSize: '0.7rem',
  },
}));

interface Props {
  component: ListCapabilities_capabilities_components;
}

const CapabilityRatings: React.FC<{ components: any }> = ({ components }) => {
  return components.map((component: any) => <CapabilityRating key={component.id} component={component} />);
};

const CapabilityRating: React.FC<Props> = ({ component }) => {
  const classes = useStyles();
  const [dialogOpen, updateDialogOpen] = useState(false);
  return (
    <>
      <LevelsOfMasteryDialog
        open={dialogOpen}
        handleClose={() => updateDialogOpen(false)}
        emerging={component.emerging}
        proficient={component.proficient}
        expert={component.expert}
      />
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          classes={{ title: classes.cardHeaderTitle }}
          title={<div className={classes.text}>{component.text}</div>}
        />
        <CardContent>
          <RatingField fieldName={`${FINAL_FORM_FIELD_PREFIX_RATING}${component.id}`} />
        </CardContent>
        <CardActions className={classes.cardActionsContainer}>
          <Tooltip title="Mastery level definitions">
            <Button
              className={classes.viewMasteryLevelsButton}
              startIcon={<HelpIcon style={{ fontSize: '2rem' }} />}
              variant="text"
              color="primary"
              size="small"
              onClick={() => updateDialogOpen(true)}
            >
              View mastery level definitions
            </Button>
          </Tooltip>
        </CardActions>
      </Card>
    </>
  );
};

export default CapabilityRatings;
