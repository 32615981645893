import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import DialogTitleWithClose from '../../components/common/DialogTitleWithClose';
import { CheckCircle, Error } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { ApolloQueryResult, gql, useApolloClient } from '@apollo/client';
import { UploadJob, UploadJob_uploadJob, UploadJobVariables } from '../../graphql';

const useStyles = makeStyles(theme => ({
  progressIcon: {
    verticalAlign: 'middle',
    color: theme.palette.primary.main,
    marginRight: 5,
  },
  statusMessageWithIcon: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  message: {
    paddingTop: 2,
  },
}));

const uploadJobGql = gql`
  query UploadJob($uploadJobId: ID!) {
    uploadJob(uploadJobId: $uploadJobId) {
      id
      status
      messages
    }
  }
`;

interface Props {
  uploadJobId: string;
  onClose: () => any;
}

const UploadProgressDialog: React.FC<Props> = ({ uploadJobId, onClose }) => {
  const [job, setJob] = useState<UploadJob_uploadJob>();
  const apolloClient = useApolloClient();

  const pollForJobResult = useCallback(
    (jobId: string) => {
      const watcher = apolloClient.watchQuery<UploadJob, UploadJobVariables>({
        fetchPolicy: 'no-cache',
        pollInterval: 4000,
        variables: { uploadJobId },
        query: uploadJobGql,
      });
      watcher.subscribe({
        next(value: ApolloQueryResult<UploadJob>) {
          const { data } = value;
          console.info(`inside next(): ${JSON.stringify(data)}`);
          if (data && data.uploadJob) {
            setJob(data.uploadJob);
            if (data.uploadJob && jobStopped(data.uploadJob)) {
              console.info('job has stopped so stop polling');
              watcher.stopPolling();
            }
          }
        },
        error(errorValue: any) {
          if (errorValue && Object.keys(errorValue.networkError).length > 0) {
            // retry on network error
            console.info(`network error occurred: ${JSON.stringify(errorValue.networkError)}`);
            pollForJobResult(jobId);
          }
        },
      });
    },
    [apolloClient, uploadJobId],
  );

  const jobStopped = (j: UploadJob_uploadJob | undefined) => j && (j.status === 'complete' || j.status === 'failed');

  useEffect(() => {
    pollForJobResult(uploadJobId);
  }, [uploadJobId, pollForJobResult]);

  const classes = useStyles();

  const getIcon = () => {
    if (job) {
      if ('complete' === job.status) {
        return <CheckCircle className={classes.progressIcon} />;
      } else if ('failed' === job.status) {
        return <Error className={classes.progressIcon} />;
      }
    }
    return <CircularProgress size={15} className={classes.progressIcon} />;
  };

  return (
    <Dialog
      open={!!uploadJobId}
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      fullWidth
      disableEscapeKeyDown
    >
      <>
        <DialogTitleWithClose title="Processing Upload" onClose={onClose} />
        <DialogContent>
          <Box className={classes.statusMessageWithIcon}>
            {getIcon()}
            <Box className={classes.message}>Upload Status: {job && job.status ? job.status : 'Initialising'}</Box>
          </Box>
          <div>
            {job && job.messages && (
              <ul>
                {job.messages.map((message: string, idx: number) => (
                  <li key={idx}>{message}</li>
                ))}
              </ul>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </>
    </Dialog>
  );
};

export default UploadProgressDialog;
