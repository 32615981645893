import { IconButton, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Archive as ArchiveIcon, Edit as EditIcon } from '@material-ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import NoDataRow from '../../components/common/NoDataRow';
import FormDialog from '../../components/form/FormDialog';
import { showMessage } from '../../components/Toast';
import omitDeep from 'omit-deep-lodash';

import {
  ListCapabilities_capabilities,
  UpdateCapability,
  UpdateCapabilityInput,
  UpdateCapabilityVariables,
} from '../../graphql';
import { Nullable } from '../../util/types';
import CapabilityFormFields from './CapabilityFormFields';
import { archiveCapabilityMutation, listCapabilitiesQuery, updateCapabilityMutation } from './queries';
import CapabilityAndCategory from './CapabilityAndCategory';

const useStyles = makeStyles(theme => ({
  row: {
    verticalAlign: 'top',
  },
  positionCol: {
    color: grey[800],
    fontWeight: 300,
  },
  componentList: {
    margin: 0,
  },
  actionsCell: {
    width: 130,
  },
}));

interface Props {
  capabilities: ListCapabilities_capabilities[];
}

const CapabilityRow = ({
  capability,
  onClickEdit,
  onClickArchive,
}: {
  capability: ListCapabilities_capabilities;
  onClickEdit: () => void;
  onClickArchive: () => void;
}) => {
  const classes = useStyles();
  return (
    <TableRow key={capability.id} className={classes.row}>
      <TableCell className={classes.positionCol}>{capability.order}</TableCell>
      <TableCell>
        <CapabilityAndCategory capability={capability} />
      </TableCell>
      <TableCell>
        <ul className={classes.componentList}>
          {capability.components.map(comp => (
            <li key={comp.id}>{comp.text}</li>
          ))}
        </ul>
      </TableCell>
      <TableCell className={classes.actionsCell}>
        <Tooltip title="Edit capability" aria-label="edit">
          <IconButton onClick={() => onClickEdit()}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Archive" aria-label="archive">
          <IconButton onClick={() => onClickArchive()}>
            <ArchiveIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

const CapabilitiesList = ({ capabilities }: Props) => {
  const classes = useStyles();
  const [capabilityToUpdate, setCapabilityToUpdate]: any = useState<Nullable<UpdateCapabilityInput>>(null);
  const [capabilityToArchive, setCapabilityToArchive]: any = useState<Nullable<UpdateCapabilityInput>>(null);
  const [updateCapability] = useMutation<UpdateCapability, UpdateCapabilityVariables>(updateCapabilityMutation, {
    awaitRefetchQueries: true,
    refetchQueries: () => [{ query: listCapabilitiesQuery }],
  });

  const [archiveCapability] = useMutation<UpdateCapability>(archiveCapabilityMutation, {
    awaitRefetchQueries: true,
    refetchQueries: () => [{ query: listCapabilitiesQuery }],
  });

  const saveUpdatedCapability = async (capability: UpdateCapabilityInput) => {
    await updateCapability({
      variables: {
        capability,
      },
    });
    setCapabilityToUpdate(null);
    showMessage(`Capability successfully updated: ${capability.name}`);
  };

  if (!capabilities) {
    return (
      <div>
        <span>An unexpected error occurred retrieving capabilities.</span>
      </div>
    );
  }

  return (
    <>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.positionCol}>#</TableCell>
            <TableCell>Category / Name</TableCell>
            <TableCell>Components</TableCell>
            <TableCell className={classes.actionsCell} />
          </TableRow>
        </TableHead>
        <TableBody>
          {capabilities.length === 0 && <NoDataRow colSpan={4} message="No capabilities configured" />}
          {capabilities.map(cap => (
            <CapabilityRow
              key={cap.id}
              capability={cap}
              onClickEdit={() => setCapabilityToUpdate(cap)}
              onClickArchive={() => setCapabilityToArchive(cap)}
            />
          ))}
        </TableBody>
      </Table>
      <FormDialog
        onSubmit={saveUpdatedCapability}
        onCancel={() => setCapabilityToUpdate(null)}
        title="Update capability"
        open={!!capabilityToUpdate}
        initialValues={capabilityToUpdate && { ...omitDeep(capabilityToUpdate, '__typename') }}
        initialValuesEqual={() => true}
        allowOverflow={false}
        maxWidth="xl"
      >
        <CapabilityFormFields existingCapabilityCount={capabilities.length} />
      </FormDialog>
      <ConfirmationDialog
        open={!!capabilityToArchive}
        title="Archive Capability?"
        onCancel={() => setCapabilityToArchive(null)}
        onConfirm={async () => {
          await archiveCapability({ variables: { capabilityId: capabilityToArchive.id } });
          showMessage(`Successfully archived capability '${capabilityToArchive.name}'`);
          setCapabilityToArchive(null);
        }}
      >
        {capabilityToArchive && (
          <span>
            Are you sure you want to archive the capability <strong>{capabilityToArchive.name}</strong>? This action
            cannot be undone.
          </span>
        )}
      </ConfirmationDialog>
    </>
  );
};

export default CapabilitiesList;
