import * as React from 'react';
import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  CreateActivity,
  CreateActivityInput,
  CreateActivityVariables,
  ListActivities,
  ListCapabilities,
} from '../../graphql';
import { createActivityMutation, listActivitiesQuery } from './queries';
import Loading from '../pages/Loading';
import { Button } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import HeaderWithTitle from '../../components/HeaderWithTitle';
import ActivitiesList from './ActivitiesList';
import { listCapabilitiesQuery } from '../capabilities/queries';
import FormDialog from '../../components/form/FormDialog';
import ActivityFormFields from './ActivityFormFields';
import { showMessage } from '../../components/Toast';

const ActivitiesPage = () => {
  const { data: activitiesData, loading: loading1 } = useQuery<ListActivities>(listActivitiesQuery);
  const { data: capabilitiesData, loading: loading2 } = useQuery<ListCapabilities>(listCapabilitiesQuery);
  const [createActivity] = useMutation<CreateActivity, CreateActivityVariables>(createActivityMutation, {
    awaitRefetchQueries: true,
    refetchQueries: () => [{ query: listActivitiesQuery }],
  });
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const addActivity = async (activity: CreateActivityInput) => {
    await createActivity({
      variables: {
        activity,
      },
    });
    await setAddDialogOpen(false);
    showMessage('Learning activity successfully added');
  };

  return (
    <>
      <HeaderWithTitle
        title="Learning Activities"
        actions={
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setAddDialogOpen(true)}
            disabled={loading1 || loading2}
          >
            Add
          </Button>
        }
      />
      {loading1 || loading2 ? (
        <Loading />
      ) : (
        <>
          <ActivitiesList activities={activitiesData!.activities} capabilities={capabilitiesData!.capabilities} />
          <FormDialog
            onSubmit={addActivity}
            onCancel={() => setAddDialogOpen(false)}
            title="Add learning activity"
            open={addDialogOpen}
            initialValues={{
              capabilityIds: [],
            }}
            initialValuesEqual={() => true}
            allowOverflow={false}
            maxWidth="xl"
          >
            <ActivityFormFields capabilities={capabilitiesData!.capabilities} />
          </FormDialog>
        </>
      )}
    </>
  );
};

export default ActivitiesPage;
