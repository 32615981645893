import { Button, CircularProgress, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { Email } from '@material-ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import DialogTitleWithClose from '../../components/common/DialogTitleWithClose';
import Form from '../../components/Form';
import Input from '../../components/form/TextField';
import { InviteUser, InviteUserVariables } from '../../graphql';
import { compose, isEmail, required } from '../../util/validation';
import RolesField from './RolesField';
import { gql, useMutation } from '@apollo/client';
import { listUsersQuery } from './queries';

const mutation = gql`
  mutation InviteUser($email: String!, $roles: [String!]!) {
    inviteUser(email: $email, roles: $roles)
  }
`;

interface FormProps {
  email: string;
  roles: string[];
}

const InviteUserDialog = () => {
  const [open, updateOpen] = useState(false);
  const [inviteUser] = useMutation<InviteUser, InviteUserVariables>(mutation, {
    awaitRefetchQueries: true,
    refetchQueries: () => [{ query: listUsersQuery }],
  });

  return (
    <React.Fragment>
      <Button variant="contained" color="primary" startIcon={<Email />} onClick={() => updateOpen(true)}>
        Invite User
      </Button>
      <Dialog
        open={open}
        onClose={(_event, reason) => {
          if (reason !== 'backdropClick') {
            updateOpen(false);
          }
        }}
        fullWidth
        disableEscapeKeyDown
      >
        {open && (
          <Form<FormProps>
            onSubmit={({ email, roles }) =>
              inviteUser({
                variables: { email, roles },
              })
            }
            initialValues={{ roles: [] }}
            successMessage="Invited user"
            onSuccess={() => updateOpen(false)}
          >
            {({ handleSubmit, submitting }: FormRenderProps) => (
              <form onSubmit={handleSubmit}>
                <DialogTitleWithClose title="Invite user" onClose={() => updateOpen(false)} />
                <DialogContent>
                  <Field
                    label="Email"
                    fullWidth
                    name="email"
                    margin="normal"
                    validate={compose(required('Email address is required'), isEmail('Must be a valid email address'))}
                    component={Input}
                  />

                  <RolesField />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => updateOpen(false)}>Cancel</Button>
                  <Button color="primary" type="submit" disabled={submitting}>
                    {submitting ? <CircularProgress size={15} /> : 'Invite'}
                  </Button>
                </DialogActions>
              </form>
            )}
          </Form>
        )}
      </Dialog>
    </React.Fragment>
  );
};

export default InviteUserDialog;
