import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import Header from './Header';

const useStyles = makeStyles(theme => ({
  button: {
    width: 300,
    marginTop: 24,
  },
}));

const LevelsOfMastery: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const history = useHistory();
  const classes = useStyles();

  const { id } = match.params;

  return (
    <div className="app">
      <Header />
      <div className="main-content">
        <h1>Levels of Mastery</h1>
        <h4>
          <p>
            There are four levels of Mastery: (1) Emerging, (2) Proficient, (3) Expert and (4) Visionary. At ANZ, we use
            these levels to understand and assess proficiency of a particular capability.
          </p>
          <p>
            Levels of Mastery terms to all aspects of growth; technical marketing competencies and leadership
            competencies.
          </p>
          <p>
            You don’t have to be at an Expert or Visionary Mastery Level in every competency to advance your career.
            Different roles require different competencies and proficiency.
          </p>
          <p>You’ll understand what’s expected for your role once you’ve completed the My Mastery Review.</p>
          <p>
            <strong>Unsure what your Level of Mastery is? Select 'Emerging'</strong>
          </p>
        </h4>

        <div>
          <Button
            onClick={() => history.push(`/${id}/caps`)}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Start your My Mastery Review
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LevelsOfMastery;
