import { FormHelperText, InputLabel, Select } from '@material-ui/core';
import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import { InputLabelProps } from '@material-ui/core/InputLabel';
import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';

interface WrappedSelectProps {
  label?: string;
  formControlProps?: FormControlProps;
  inputLabelProps?: InputLabelProps;
  hideErrorText?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode) => void;
}

const SelectField: React.FC<FieldRenderProps<string | string[], any> & WrappedSelectProps> = ({
  input: { name, value, onChange: inputOnChange, ...restInput },
  meta,
  label,
  formControlProps,
  inputLabelProps,
  hideErrorText,
  onChange: customOnChange,
  ...rest
}) => {
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  return (
    <FormControl {...formControlProps} error={showError}>
      {label && (
        <InputLabel {...inputLabelProps} htmlFor={name}>
          {label}
        </InputLabel>
      )}

      <Select
        {...rest}
        name={name}
        onChange={(event: any, updateFieldValue) => {
          inputOnChange(event);
          if (customOnChange) {
            customOnChange(event, updateFieldValue);
          }
        }}
        inputProps={restInput}
        value={value}
      />

      {showError && !hideErrorText && <FormHelperText>{meta.error || meta.submitError}</FormHelperText>}
    </FormControl>
  );
};

export default SelectField;
