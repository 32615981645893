import { Button, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import Form from '../../components/Form';
import Input from '../../components/form/TextField';
import { ConfirmResetPassword, ConfirmResetPasswordVariables } from '../../graphql';
import { required } from '../../util/validation';
import AccountPage from './AccountPage';
import { gql, useMutation } from '@apollo/client';

const useStyles = makeStyles(theme => ({
  submit: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
  google: {
    width: '100%',
    marginTop: theme.spacing(4),
    background: theme.palette.common.white,
  },
}));

const confirmPasswordResetMutation = gql`
  mutation ConfirmResetPassword($password: String!, $code: String!) {
    confirmResetPassword(newPassword: $password, code: $code)
  }
`;

interface FormData {
  password: string;
  confirmPassword: string;
}

const validatePasswordConfirmation = (value: string, allValues: any) =>
  value !== allValues.password ? 'Password confirmation must match' : undefined;

const ConfirmReset: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { params } = useRouteMatch<{ code: string }>();
  const [confirmPasswordReset] = useMutation<ConfirmResetPassword, ConfirmResetPasswordVariables>(
    confirmPasswordResetMutation,
  );

  return (
    <AccountPage
      title="Reset password"
      links={
        <>
          <Link to="/signin">Signing in?</Link>
        </>
      }
    >
      <Form<FormData>
        onSubmit={({ password }) => confirmPasswordReset({ variables: { password, code: params.code } })}
        successMessage="Password successfully reset"
        onSuccess={() => history.push(`/signin`)}
      >
        {({ handleSubmit, submitting }: FormRenderProps) => (
          <form onSubmit={handleSubmit}>
            <Field
              label="New password"
              fullWidth
              name="password"
              type="password"
              margin="normal"
              validate={required('Password is required')}
              component={Input}
            />

            <Field
              label="Confirm new password"
              fullWidth
              name="confirm_password"
              type="password"
              margin="normal"
              validate={validatePasswordConfirmation}
              component={Input}
            />

            <Button type="submit" color="primary" variant="contained" className={classes.submit} disabled={submitting}>
              Reset password
            </Button>
          </form>
        )}
      </Form>
    </AccountPage>
  );
};

export default ConfirmReset;
