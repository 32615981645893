import { FORM_ERROR, FormApi } from 'final-form';
import * as _ from 'lodash';
import { showErrorMessage, showMessage } from '../components/Toast';
import { ErrorResponse } from '@apollo/client/link/error';
import { ServerError, ServerParseError } from '@apollo/client';

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OneOrMany<T> = T | ReadonlyArray<T>;

interface SafeSubmitOptions<R> {
  successMessage: string;
  onSuccess: (result: R, formApi: FormApi) => void;
}

type FormHandler<T> = (data: Partial<T>, formApi: FormApi) => Promise<any>;
export function safeSubmit<T, R = {}>(
  handler: FormHandler<T>,
  config: Partial<SafeSubmitOptions<R>> = {},
): FormHandler<T> {
  return async (data, api) => {
    try {
      const result = await handler(data, api);

      if (config.successMessage) {
        showMessage(config.successMessage);
      }

      if (config.onSuccess) {
        console.log(api);
        config.onSuccess(result, api);
      }

      return result;
    } catch (ex: unknown) {
      let message: string;
      console.log(JSON.stringify(ex));
      if (isGraphQLError(ex)) {
        message = _.get(ex.graphQLErrors, [0, 'message'], 'Unexpected error submitting form');
      } else {
        message = (isError(ex) && ex.message) || 'Unexpected error submitting form';
      }

      showErrorMessage(message);

      return {
        [FORM_ERROR]: message,
      };
    }
  };
}

export const toPercentage = (input: number) => `${(input * 100).toFixed(2)}%`;

const isGraphQLError = (error: unknown): error is ErrorResponse => {
  return (error as ErrorResponse).graphQLErrors !== undefined;
};

const isError = (error: unknown): error is Error => {
  return (error as Error).message !== undefined;
};

export const isServerError = (error: unknown): error is ServerError | ServerParseError => {
  return (error as ServerError | ServerParseError).statusCode !== undefined;
};
