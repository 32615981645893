import { Button, CircularProgress, Link, Paper } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { chain, toPairs } from 'lodash';
import * as React from 'react';
import { useMutation } from '@apollo/client';
import Form from '../../../components/Form';
import { showMessage } from '../../../components/Toast';
import {
  ListCapabilities_capabilities,
  RoleById_roleById_expectedMasteryLevels,
  UpdateExpectedMasteryLevels,
  UpdateExpectedMasteryLevelsVariables,
} from '../../../graphql';
import { updateExpectedMasteryLevelsMutation } from '../queries';
import CapabilityMasteryLevels from './CapabilityMasteryLevels';
import { FormRenderProps } from 'react-final-form';

const useStyles = makeStyles(theme => ({
  noData: {
    margin: theme.spacing(4, 0),
    height: 200,
    display: 'flex',
    color: grey[600],
    flexDirection: 'column',
    justifyContent: 'center',
    '& div': {
      textAlign: 'center',
    },
  },
  formActions: {
    textAlign: 'right',
    margin: theme.spacing(2, 0),
  },
  actionButton: {
    width: 135,
  },
}));

interface Props {
  roleId: string;
  capabilities?: ListCapabilities_capabilities[];
  existingMasteryLevels: RoleById_roleById_expectedMasteryLevels[];
}

const RoleMasteryLevelsForm: React.FC<Props> = ({ roleId, capabilities, existingMasteryLevels }) => {
  const classes = useStyles();

  const [updateExpectedMasteryLevels] = useMutation<UpdateExpectedMasteryLevels, UpdateExpectedMasteryLevelsVariables>(
    updateExpectedMasteryLevelsMutation,
  );

  const initialValues = chain(existingMasteryLevels)
    .keyBy('componentId')
    .mapValues(obj => `${obj.level}`)
    .value();

  if (!capabilities || !capabilities.length) {
    return (
      <Paper className={classes.noData}>
        <div>
          No capabilities have been configured. Go to the <Link href="/admin/capabilities">Capabilities Page</Link> to
          configure capabilities.
        </div>
      </Paper>
    );
  }

  const onSubmit = async (vals: { [key: string]: string }) => {
    const expectedMasteryLevels = toPairs(vals).map(([componentId, level]) => ({
      componentId,
      level: parseInt(level, 10),
    }));
    const updatedRole = await updateExpectedMasteryLevels({
      variables: {
        roleId,
        expectedMasteryLevels,
      },
    });
    window.scrollTo(0, 0);
    showMessage('Successfully updated expected mastery levels');
    return updatedRole;
  };

  const capabilityMasteryLevels = capabilities.map(capability => (
    <CapabilityMasteryLevels key={capability.id} capability={capability} />
  ));

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, submitting }: FormRenderProps) => (
        <>
          <div>{capabilityMasteryLevels}</div>
          <div className={classes.formActions}>
            <Button
              className={classes.actionButton}
              variant="contained"
              color="primary"
              startIcon={submitting ? <CircularProgress size={15} /> : undefined}
              disabled={submitting}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </>
      )}
    </Form>
  );
};

export default RoleMasteryLevelsForm;
