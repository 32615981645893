import React from 'react';
import { AssessmentRoundMasteryByComponents_assessmentRoundMasteryByComponents } from '../../graphql';
import LinkButton from '../../components/LinkButton';
import { ChevronLeft as BackIcon } from '@material-ui/icons';
import HeaderWithTitle from '../../components/HeaderWithTitle';
import { makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import CompletionByRegions from './CompletionByRegions';
import CapabilityAndCategory from '../capabilities/CapabilityAndCategory';

interface Props {
  roundName: string;
  data: AssessmentRoundMasteryByComponents_assessmentRoundMasteryByComponents;
}

const useStyles = makeStyles(theme => ({
  title: {
    color: '#012236', // darker blue
  },
  paperContainer: {
    marginTop: theme.spacing(2),
    width: '90%',
    minWidth: 600,
    maxWidth: 1400,
    padding: theme.spacing(2),
  },
}));

const MasteryByComponent: React.FC<Props> = ({ roundName, data }) => {
  const classes = useStyles();
  return (
    <>
      <LinkButton startIcon={<BackIcon />} to="/admin/assessment-rounds">
        Assessment Rounds
      </LinkButton>
      <HeaderWithTitle titleClass={classes.title} title="Assessment round mastery by component" subTitle={roundName} />
      <Paper className={classes.paperContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Category / Name</TableCell>
              <TableCell>Component</TableCell>
              <TableCell>Mastery levels met</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.capabilities.map(capability =>
              capability.components.map(component => (
                <TableRow key={component.text}>
                  <TableCell>
                    <CapabilityAndCategory capability={capability} />
                  </TableCell>
                  <TableCell>{component.text}</TableCell>
                  <TableCell width={300}>
                    <CompletionByRegions regionStats={component.regionStats} />
                  </TableCell>
                </TableRow>
              )),
            )}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default MasteryByComponent;
