import expert from '../assets/expert.png';
import proficient from '../assets/proficient.png';
import emerging from '../assets/emerging.png';
import visionary from '../assets/visionary.png';

export interface LevelOfMasteryInfo {
  title: string;
  image: string;
  key: string;
}

const EMERGING: LevelOfMasteryInfo = {
  title: '1. Emerging',
  image: emerging,
  key: 'emerging',
};

const PROFICIENT: LevelOfMasteryInfo = {
  title: '2. Proficient',
  image: proficient,
  key: 'proficient',
};

const EXPERT: LevelOfMasteryInfo = {
  title: '3. Expert',
  image: expert,
  key: 'expert',
};

const VISIONARY: LevelOfMasteryInfo = {
  title: '4. Visionary',
  image: visionary,
  key: 'visionary',
};

const LEVELS_OF_MASTERY = {
  EMERGING,
  PROFICIENT,
  EXPERT,
  VISIONARY,
};

export default LEVELS_OF_MASTERY;
