import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { RouteComponentProps } from 'react-router-dom';
import { AssessmentRoundParticipants, AssessmentRoundParticipantsVariables } from '../../graphql';
import LoadingMask from '../pages/LoadingMask';
import ParticipantsList from './ParticipantsList';
import { assessmentRoundParticipantsQuery } from './queries';

interface Params {
  roundName: string;
}

const AssessmentRoundParticipantsPage: React.FC<RouteComponentProps<Params>> = ({
  match: {
    params: { roundName },
  },
}) => {
  const [includeArchived, setIncludeArchived] = useState<boolean>(false);
  const { data, loading } = useQuery<AssessmentRoundParticipants, AssessmentRoundParticipantsVariables>(
    assessmentRoundParticipantsQuery,
    {
      variables: { roundName, includeArchived },
    },
  );

  if (loading) return <LoadingMask />;

  return (
    <ParticipantsList
      roundName={roundName}
      participants={data!.assessmentRoundParticipants.participants}
      includeArchived={includeArchived}
      onToggleArchived={setIncludeArchived}
    />
  );
};

export default AssessmentRoundParticipantsPage;
