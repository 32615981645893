import { FormApi } from 'final-form';
import * as React from 'react';
import { Form as FinalForm, FormProps } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Omit, safeSubmit } from '../util/util';

interface Props<T, U = {}> extends Omit<FormProps, 'onSubmit'> {
  onSubmit: (data: T, formApi: FormApi) => Promise<U | void>;
  onSuccess?: (result: U, formApi: FormApi) => void;
  successMessage?: string;
}

const Form = <T, U = {}>({ onSubmit, successMessage, onSuccess, ...rest }: Props<T, U>) => {
  return (
    <FinalForm
      {...rest}
      onSubmit={safeSubmit(onSubmit as any, {
        successMessage,
        onSuccess,
      })}
      mutators={{
        ...arrayMutators,
      }}
    />
  );
};

export default Form;
