import React from 'react';
import { AssessmentRoundStatistics_assessmentRoundStatistics } from '../../graphql';
import { makeStyles, Typography } from '@material-ui/core';
import { toPercentage } from '../../util/util';

const useStyles = makeStyles(theme => ({
  statisticHeading: {
    display: 'inline-block',
    width: 110,
  },
  statisticData: {
    display: 'inline-block',
    marginLeft: theme.spacing(2),
    width: 40,
    textAlign: 'right',
  },
  statisticsTotal: {
    fontSize: '1.3rem',
  },
}));

type Props = {
  assessmentRoundStatistics: AssessmentRoundStatistics_assessmentRoundStatistics;
};

const StatisticsRow: React.FC<Props> = ({ assessmentRoundStatistics }) => {
  const classes = useStyles();
  return (
    <ul className="list-unstyled">
      <li>
        <span className={classes.statisticHeading}>Total invited:</span>
        <span className={classes.statisticData}>
          <Typography className={classes.statisticsTotal} variant="body1">
            {assessmentRoundStatistics.total}
          </Typography>
        </span>
      </li>
      <li>
        <span className={classes.statisticHeading}>Not commenced:</span>
        <span className={classes.statisticData}>
          <Typography className={classes.statisticsTotal} variant="body1">
            {assessmentRoundStatistics.invited.total}
          </Typography>
        </span>
        <span className={classes.statisticData}>
          <Typography variant="caption">{toPercentage(assessmentRoundStatistics.invited.percentageInRound)}</Typography>
        </span>
      </li>
      <li>
        <span className={classes.statisticHeading}>In progress:</span>
        <span className={classes.statisticData}>
          <Typography className={classes.statisticsTotal} variant="body1">
            {assessmentRoundStatistics.inProgress.total}
          </Typography>
        </span>
        <span className={classes.statisticData}>
          <Typography variant="caption">
            {toPercentage(assessmentRoundStatistics.inProgress.percentageInRound)}
          </Typography>
        </span>
      </li>
      <li>
        <span className={classes.statisticHeading}>Complete:</span>
        <span className={classes.statisticData}>
          <Typography className={classes.statisticsTotal} variant="body1">
            {assessmentRoundStatistics.complete.total}
          </Typography>
        </span>
        <span className={classes.statisticData}>
          <Typography variant="caption">
            {toPercentage(assessmentRoundStatistics.complete.percentageInRound)}
          </Typography>
        </span>
      </li>
    </ul>
  );
};

export default StatisticsRow;
