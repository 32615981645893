import { gql } from '@apollo/client';

export const listUsersQuery = gql`
  query ListUsers {
    users {
      id
      name
      email
      enabled
      invited
      avatar {
        url
      }
      roles
    }
  }
`;

export const userDetailsQuery = gql`
  query UserDetails($userId: ID!) {
    userById(id: $userId) {
      id
      name
      email
      roles
      enabled
      invited
    }
  }
`;

export const meQuery = gql`
  query Me {
    me {
      id
      name
      roles
    }
  }
`;
