import { FormHelperText, makeStyles } from '@material-ui/core';
import { AnyObject } from 'final-form';
import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {
  CapabilityCommentInput,
  ComponentRatingInput,
  GetAssessment,
  GetAssessment_assessment,
  GetAssessmentVariables,
  ListCapabilities,
  ListCapabilities_capabilities,
  SaveAssessment,
  SaveAssessmentVariables,
} from '../graphql';
import { listCapabilitiesQuery } from '../modules/capabilities/queries';
import Loading from '../modules/pages/Loading';
import LoadingMask from '../modules/pages/LoadingMask';
import Form from './Form';
import Header from './Header';
import CapabilityComment from './CapabilityComment';
import CapabilityHeader from './CapabilityHeader';
import CapabilityActionButtons from './CapabilityActionButtons';
import CapabilityRatings from './CapabilityRating';
import { FormRenderProps } from 'react-final-form';

export const FINAL_FORM_FIELD_PREFIX_RATING = 'field-rating';
export const FINAL_FORM_FIELD_PREFIX_COMMENT = 'field-comment';

const getAssessment = gql`
  query GetAssessment($id: ID!) {
    assessment(id: $id) {
      componentRatings {
        id
        level
      }
      capabilityComments {
        id
        comment
      }
    }
  }
`;

const saveAssessmentMutation = gql`
  mutation SaveAssessment(
    $id: ID!
    $componentRatings: [ComponentRatingInput!]!
    $capabilityComments: [CapabilityCommentInput!]!
    $complete: Boolean!
  ) {
    saveAssessment(
      id: $id
      componentRatings: $componentRatings
      capabilityComments: $capabilityComments
      complete: $complete
    )
  }
`;

const useStyles = makeStyles(theme => ({
  error: {
    marginTop: 16,
  },
  text: {
    fontSize: 14,
    color: '#83A2B8',
    textAlign: 'left',
  },
}));

interface FormProps {
  [idWithPrefix: string]: string;
}

const toDto = (data: FormProps): Pick<SaveAssessmentVariables, 'componentRatings' | 'capabilityComments'> => {
  const componentRatings: ComponentRatingInput[] = Object.keys(data)
    .filter(key => key.startsWith(FINAL_FORM_FIELD_PREFIX_RATING))
    .map(idWithPrefix => ({
      id: idWithPrefix.substr(FINAL_FORM_FIELD_PREFIX_RATING.length),
      level: parseInt(data[idWithPrefix], 10),
    }));

  const capabilityComments: CapabilityCommentInput[] = Object.keys(data)
    .filter(key => key.startsWith(FINAL_FORM_FIELD_PREFIX_COMMENT))
    .map(idWithPrefix => ({
      id: idWithPrefix.substr(FINAL_FORM_FIELD_PREFIX_COMMENT.length),
      comment: data[idWithPrefix],
    }));

  return { componentRatings, capabilityComments };
};

const fromDto = (data: GetAssessment_assessment) => {
  const componentRatings = data.componentRatings.reduce(
    (map, { id, level }) => ({
      ...map,
      [`${FINAL_FORM_FIELD_PREFIX_RATING}${id}`]: `${level}`,
    }),
    {},
  );

  const compatibilityComments = data.capabilityComments.reduce(
    (map, { id, comment }) => ({
      ...map,
      [`${FINAL_FORM_FIELD_PREFIX_COMMENT}${id}`]: `${comment}`,
    }),
    {},
  );

  return { ...componentRatings, ...compatibilityComments };
};

const isValid = (caps: ListCapabilities_capabilities, formValues: AnyObject) =>
  caps.components.every(cap => !!formValues[`${FINAL_FORM_FIELD_PREFIX_RATING}${cap.id}`]);

const getCurrentStep = (caps: ListCapabilities_capabilities[], answered: number): number => {
  let step = 0;
  let capCount = 0;
  do {
    if (caps[step]) {
      capCount += caps[step].components.length;
      step++;
    } else {
      // no more caps - stop looping
      break;
    }
  } while (capCount <= answered);
  return step;
};

const Capabilities: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const { id } = match.params;
  const history = useHistory();
  const classes = useStyles();
  const [step, setStep] = useState(1);
  const [showError, setShowError] = useState(false);
  const [open, setOpen] = useState(false);

  const { data: capabilitiesData, loading: loading1 } = useQuery<ListCapabilities>(listCapabilitiesQuery);
  const { data: assessmentData, loading: loading2 } = useQuery<GetAssessment, GetAssessmentVariables>(getAssessment, {
    variables: { id },
  });

  const [saveAssessment, { loading: submitting }] = useMutation<SaveAssessment, SaveAssessmentVariables>(
    saveAssessmentMutation,
  );

  useEffect(() => {
    if (capabilitiesData && assessmentData && assessmentData.assessment) {
      const currentStep = getCurrentStep(
        capabilitiesData.capabilities,
        assessmentData.assessment.componentRatings.length,
      );
      setStep(currentStep);
    }
  }, [capabilitiesData, assessmentData]);

  if (loading1 || loading2) {
    return <Loading />;
  }

  const caps = capabilitiesData!.capabilities;
  const capability = caps[step - 1];
  const initialFormValues = fromDto(assessmentData!.assessment);

  const prev = () => {
    setStep(step - 1);
    window.scrollTo(0, 0);
  };

  const next = (formData: FormProps) => {
    if (isValid(capability, formData)) {
      setOpen(true);
      setShowError(false);
      saveAssessment({ variables: { id, ...toDto(formData), complete: false } });
      setTimeout(() => {
        setOpen(false);
        setStep(step + 1);
        window.scrollTo(0, 0);
      }, 1500);
    } else {
      setShowError(true);
    }
  };

  return (
    <div className="app">
      {submitting && step === caps.length && <LoadingMask />}
      <Header />
      <div className="main-content">
        <CapabilityHeader capability={capability} step={step} steps={caps.length} />
        <Form<FormProps>
          onSubmit={formData => saveAssessment({ variables: { id, ...toDto(formData), complete: true } })}
          initialValues={initialFormValues}
          onSuccess={() => history.replace('/submitted')}
        >
          {({ handleSubmit, values }: FormRenderProps) => (
            <form
              onSubmit={e => {
                if (isValid(capability, values)) {
                  handleSubmit(e);
                } else {
                  e.preventDefault();
                  setShowError(true);
                }
              }}
            >
              <CapabilityRatings components={capability.components} />
              <CapabilityComment capabilityId={capability.id} />
              {showError && !isValid(capability, values) && (
                <FormHelperText error={true} className={classes.error}>
                  Please select your level for every capability
                </FormHelperText>
              )}

              <CapabilityActionButtons
                step={step}
                steps={caps.length}
                onClickNext={() => next(values)}
                onClickPrev={prev}
              />
              {open && <LoadingMask message="Saving Progress" />}
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};

export default Capabilities;
