import React from 'react';
import { useQuery } from '@apollo/client';
import { RouteComponentProps } from 'react-router-dom';
import LoadingMask from '../pages/LoadingMask';
import { assessmentRoundMasteryByComponentsQuery } from './queries';
import { AssessmentRoundMasteryByComponents } from '../../graphql';
import MasteryByComponent from './MasteryByComponent';

interface Params {
  roundName: string;
}

const AssessmentRoundMasteryByComponentPage: React.FC<RouteComponentProps<Params>> = ({
  match: {
    params: { roundName },
  },
}) => {
  const { data, loading } = useQuery<AssessmentRoundMasteryByComponents>(assessmentRoundMasteryByComponentsQuery, {
    variables: { roundName },
  });
  if (loading) return <LoadingMask />;

  return data ? <MasteryByComponent roundName={roundName} data={data.assessmentRoundMasteryByComponents} /> : null;
};

export default AssessmentRoundMasteryByComponentPage;
