import { MuiThemeProvider } from '@material-ui/core/styles';
import * as React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import Capabilities from './components/Capabilities';
import LevelsOfMastery from './components/LevelsOfMastery';
import Submitted from './components/Submitted';
import UnsupportedBrowser from './components/UnsupportedBrowser';
import Welcome from './components/Welcome';
import Activate from './modules/accounts/Activate';
import ConfirmReset from './modules/accounts/ConfirmReset';
import Reset from './modules/accounts/Reset';
import SignIn from './modules/accounts/SignIn';
import AdminRoutes from './routes/AdminRoutes';
import RoleRedirect from './routes/RoleRedirect';
import theme from './theme';

const App = () => {
  const isIe = !!(document as any).documentMode;
  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <Switch>
          {isIe && (
            <>
              <Route exact path="/unsupported-browser" component={UnsupportedBrowser} />
              <Redirect to="/unsupported-browser" />
            </>
          )}
          <Route exact path="/" component={RoleRedirect} />
          <Route exact path="/submitted" component={Submitted} />
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/reset" component={Reset} />
          <Route exact path="/activate/:code" component={Activate} />
          <Route exact path="/confirm-reset/:code" component={ConfirmReset} />
          <Route path="/admin" component={AdminRoutes} />
          {/* these need to come last */}
          <Route exact path="/:id" component={Welcome} />
          <Route exact path="/:id/levels" component={LevelsOfMastery} />
          <Route exact path="/:id/caps" component={Capabilities} />
        </Switch>
      </Router>
    </MuiThemeProvider>
  );
};

export default App;
