import {makeStyles, MenuItem} from '@material-ui/core';
import * as React from 'react';
import { Field } from 'react-final-form';
import SelectField from '../../components/form/SelectField';
import Input from '../../components/form/TextField';
import {required} from '../../util/validation';
import {ActivityType, ListCapabilities_capabilities} from "../../graphql";
import ChecklistField from "../../components/form/ChecklistField";

const useStyles = makeStyles(theme => ({
  selectField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(),
    minWidth: 150,
  },
  standardFields: {
    marginRight: theme.spacing(2),
  },
  capabilityComponents: {
    marginLeft: theme.spacing(4),
  },
  capabilityComponent: {
    display: 'flex',
  },
  componentFieldWrapper: {
    flex: 1,
    '& .MuiInputBase-input': {
      fontSize: 14,
    },
  },
  componentActions: {
    paddingTop: theme.spacing(3),
    textAlign: 'right',
    marginLeft: theme.spacing(2),
  },
  componentsLabel: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  addComponentButtonContainer: {
    textAlign: 'right',
    marginTop: 8,
  },
  moveComponentButton: {
    cursor: 'grab',
    paddingLeft: 0,
    paddingRight: 0,
  },
  fieldRow: {
    display: 'flex',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(4),
    },
    '&:not(:first-child)': {
      marginTop: theme.spacing(),
    },
  },
}));

interface Props {
  capabilities: ListCapabilities_capabilities[];
}

const ActivityFormFields = ({ capabilities }: Props) => {

  const classes = useStyles();

  return (
    <>
      <div className={classes.standardFields}>
        <div className={classes.fieldRow}>
          <Field
            component={SelectField}
            name="type"
            label="Type"
            formControlProps={{ className: classes.selectField }}
            labelWidth={50}
            validate={required('Type is required')}
          >
            {Object.keys(ActivityType).map(val => (
              <MenuItem key={val} value={val}>
                {val}
              </MenuItem>
            ))}
          </Field>
          <Field
            component={Input}
            name="description"
            label="Description"
            margin="normal"
            fullWidth
            multiline
            parse={value => value}
            validate={required('Description is required')}
          />
        </div>
        <div className={classes.fieldRow}>
          <Field
            component={ChecklistField}
            name="capabilityIds"
            label="Linked capabilities"
            margin="normal"
            fullWidth
            multiline
            options={capabilities.map(cap => ({
              label: `${cap.name} (${cap.category})`,
              value: cap.id,
            }))}
          />
        </div>
      </div>
    </>
  );
};

export default ActivityFormFields;
