import { makeStyles } from '@material-ui/core';
import React from 'react';
import MarketingMastersLogo from '../assets/marketing-masters.png';

const useStyles = makeStyles(theme => ({
  image: {
    marginTop: 16,
    width: 200,
  },
  text: {
    color: '#fff',
    fontSize: 13,
  },
}));

const NoInvite = () => {
  const classes = useStyles();

  return (
    <div className="welcome">
      <div className="main-content">
        <img src={MarketingMastersLogo} alt="" className={classes.image} />
        <h3>Access link required</h3>
        <div className={classes.text}>
          Please use the link provided in your invitation email to access Marketing Masters My Mastery Review.
        </div>
      </div>
    </div>
  );
};

export default NoInvite;
