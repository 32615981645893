import { Paper, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import RatingField from '../../../components/RatingField';
import { ListCapabilities_capabilities } from '../../../graphql';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(4, 0),
  },
  title: {
    margin: theme.spacing(2, 0),
    color: theme.palette.secondary.main,
  },
  categoryText: {
    fontWeight: 'bold',
  },
  capabilityText: {
    fontWeight: 300,
  },
  components: {
    padding: theme.spacing(2),
  },
  componentText: {
    color: grey[600],
  },
  masteryLevel: {
    width: 400,
  },
  radioText: {
    fontSize: 11,
  },
}));

interface Props {
  capability: ListCapabilities_capabilities;
}

const CapabilityMasteryLevels: React.FC<Props> = ({ capability }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h6">
        <span className={classes.categoryText}>{capability.category}</span>:{' '}
        <span className={classes.capabilityText}>{capability.name}</span>
      </Typography>
      <Paper className={classes.components}>
        <Table>
          <TableBody>
            {capability.components.map(component => (
              <TableRow key={component.id}>
                <TableCell className={classes.componentText}>{component.text}</TableCell>
                <TableCell className={classes.masteryLevel}>
                  <RatingField fieldName={component.id} radioTextClass={classes.radioText} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default CapabilityMasteryLevels;
