import { Button, Dialog, DialogContent, makeStyles, CircularProgress } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';
import { FormProps, FormRenderProps } from 'react-final-form';
import DialogTitleWithClose from '../common/DialogTitleWithClose';
import Form from '../Form';

const useStyles = makeStyles(theme => ({
  allowOverflow: {
    overflowY: 'visible',
  },
  padded: {
    paddingRight: theme.spacing(2),
  },
  formFields: {
    paddingBottom: theme.spacing(),
  },
  dialogActions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(),
    textAlign: 'right',
  },
}));

interface Props extends FormProps {
  title: string;
  onSubmit: (values: any) => Promise<void> | void;
  onCancel: () => void;
  initialValues?: any;
  onDelete?: (values: any) => Promise<void> | void;
  open?: boolean;
  cancelButtonText?: string;
  submitButtonText?: string;
  deleteButtonText?: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  allowOverflow?: boolean;
  disabled?: boolean;
  fullScreen?: boolean;
}

const FormDialog: React.FC<Props> = ({
  title,
  onCancel,
  onDelete,
  onSubmit,
  initialValues = {},
  open = false,
  allowOverflow = true,
  cancelButtonText = 'Cancel',
  submitButtonText = 'Submit',
  deleteButtonText = 'Delete',
  maxWidth = 'sm',
  disabled = false,
  fullScreen = false,
  children,
  ...rest
}) => {
  const classes = useStyles();
  const handleDelete = () => onDelete && onDelete(initialValues);

  const doSubmit = (values: any) => Promise.resolve(onSubmit(values));

  const handleCancel = () => {
    // can reset state here if we ever have any
    return onCancel();
  };

  return (
    <Dialog
      classes={{ paper: allowOverflow ? classes.allowOverflow : '' }}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleCancel();
        }
      }}
      fullWidth
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      disableEscapeKeyDown
    >
      <DialogTitleWithClose title={title} onClose={handleCancel} />
      <DialogContent className={cx({ [classes.allowOverflow]: allowOverflow })}>
        <Form onSubmit={doSubmit} initialValues={initialValues} {...rest}>
          {({ handleSubmit, submitting }: FormRenderProps) => (
            <div>
              <div className={classes.formFields}>{children}</div>
              <div className={classes.dialogActions}>
                <Button variant="text" onClick={handleCancel}>
                  {cancelButtonText}
                </Button>
                {onDelete && (
                  <Button variant="text" color="secondary" onClick={handleDelete}>
                    {deleteButtonText}
                  </Button>
                )}
                <Button variant="text" color="primary" disabled={disabled || submitting} onClick={handleSubmit}>
                  {submitting ? <CircularProgress size={15} /> : submitButtonText}
                </Button>
              </div>
            </div>
          )}
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default FormDialog;
