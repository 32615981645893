import { makeStyles } from '@material-ui/core';
import React from 'react';
import MarketingMastersLogo from '../assets/marketing-masters.png';

const useStyles = makeStyles(theme => ({
  image: {
    marginTop: 16,
    width: 200,
  },
  text: {
    color: '#fff',
    fontSize: 13,
  },
}));

const Submitted = () => {
  const classes = useStyles();
  return (
    <div className="welcome">
      <div className="main-content">
        <img src={MarketingMastersLogo} alt="" className={classes.image} />
        <h3>My Mastery Review Successfully Completed</h3>
        <div className={classes.text}>
          <p>
            Your results have been emailed to your ANZ email address. Please make sure you save a copy for your records.
            A copy of the PDF report has also been automatically emailed to your People Leader.
          </p>
          <p>The next step is for you to discuss your results with your People Leader.</p>
        </div>
      </div>
    </div>
  );
};

export default Submitted;
