import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Hidden,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import React from 'react';
import expertIcon from '../assets/expert.png';
import proficientIcon from '../assets/proficient.png';
import emergingIcon from '../assets/emerging.png';
import visionaryIcon from '../assets/visionary.png';
import DialogTitleWithClose from './common/DialogTitleWithClose';

type Props = {
  open: boolean;
  handleClose: () => void;
  emerging: string;
  proficient: string;
  expert: string;
};

const useStyles = makeStyles(theme => ({
  listItem: {
    paddingLeft: 0,
    alignItems: 'flex-start',
  },
  avatar: {
    marginTop: theme.spacing(1),
  },
}));

const VISIONARY_DEFINITION = `You are recognised internally and externally for expertise, thought leadership and
are inspired to invent and influence better practice. You are likely to be published, actively contributing to building
capability internally and externally in your area of specialisation; with a focus on strengthening your craft. Note: very few
people will reach this level of Mastery and there may not be a requirement for this level of mastery within the organisation.`;

const LevelsOfMasteryDialog: React.FC<Props> = ({ open, handleClose, emerging, proficient, expert }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitleWithClose title="Mastery level definitions" onClose={handleClose} />
      <DialogContent>
        <List>
          <ListItem className={classes.listItem}>
            <Hidden xsDown>
              <ListItemAvatar className={classes.avatar}>
                <Tooltip title="Emerging">
                  <Avatar alt="Emerging" src={emergingIcon} />
                </Tooltip>
              </ListItemAvatar>
            </Hidden>
            <ListItemText primary="Emerging" secondary={<div dangerouslySetInnerHTML={{ __html: emerging }} />} />
          </ListItem>
          <ListItem className={classes.listItem}>
            <Hidden xsDown>
              <ListItemAvatar className={classes.avatar}>
                <Tooltip title="Proficient">
                  <Avatar alt="Proficient" src={proficientIcon} />
                </Tooltip>
              </ListItemAvatar>
            </Hidden>
            <ListItemText primary="Proficient" secondary={<div dangerouslySetInnerHTML={{ __html: proficient }} />} />
          </ListItem>
          <ListItem className={classes.listItem}>
            <Hidden xsDown>
              <ListItemAvatar className={classes.avatar}>
                <Tooltip title="Expert">
                  <Avatar alt="Expert" src={expertIcon} />
                </Tooltip>
              </ListItemAvatar>
            </Hidden>
            <ListItemText primary="Expert" secondary={<div dangerouslySetInnerHTML={{ __html: expert }} />} />
          </ListItem>
          <ListItem className={classes.listItem}>
            <Hidden xsDown>
              <ListItemAvatar className={classes.avatar}>
                <Tooltip title="Visionary">
                  <Avatar alt="Visionary" src={visionaryIcon} />
                </Tooltip>
              </ListItemAvatar>
            </Hidden>
            <ListItemText primary="Visionary" secondary={VISIONARY_DEFINITION} />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LevelsOfMasteryDialog;
