import { CircularProgress, makeStyles, TableCell, TableRow, Select, MenuItem } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React from 'react';
import { useQuery } from '@apollo/client';
import DateTime from '../../components/common/DateTime';
import { AssessmentRoundStatistics, ListAssessmentRounds_assessmentRounds } from '../../graphql';
import { assessmentRoundStatisticsQuery } from './queries';
import StatisticsRow from './StatisticsRow';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  inviteDateLabel: {
    fontWeight: 'bold',
    color: grey[600],
    display: 'inline-block',
    width: '2.9em',
  },
  row: {
    verticalAlign: 'top',
  },
  actionsCellContainer: {
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
  },
  reportSelect: {
    width: 120,
  },
}));

const AssessmentRoundRow = ({ assessmentRound }: { assessmentRound: ListAssessmentRounds_assessmentRounds }) => {
  const classes = useStyles();
  const { data, loading } = useQuery<AssessmentRoundStatistics>(assessmentRoundStatisticsQuery, {
    variables: { roundName: assessmentRound.id },
  });

  const history = useHistory();
  return (
    <TableRow className={classes.row}>
      <TableCell>{assessmentRound.id}</TableCell>
      <TableCell>
        <ul className="list-unstyled">
          <li>
            <span className={classes.inviteDateLabel}>First: </span>
            <DateTime value={assessmentRound.createdAt} />
          </li>
          <li>
            <span className={classes.inviteDateLabel}>Last: </span>
            <DateTime value={assessmentRound.lastInvitedAt} />
          </li>
        </ul>
      </TableCell>
      <TableCell>
        {loading && <CircularProgress />}
        {!loading && <StatisticsRow assessmentRoundStatistics={data!.assessmentRoundStatistics} />}
      </TableCell>
      <TableCell className={classes.actionsCellContainer}>
        {data && data.assessmentRoundStatistics.total > 0 && (
          <Select
            onChange={e => {
              history.push(`assessment-rounds/${assessmentRound.id}/${e.target.value}`);
            }}
            value="UNSELECTED"
            className={classes.reportSelect}
            disableUnderline
          >
            <MenuItem value="UNSELECTED" disabled>
              View report
            </MenuItem>
            <MenuItem value={'participants'}>Participants</MenuItem>
            <MenuItem value={'completion-stats-by-role'}>Completion stats by role/region</MenuItem>
            <MenuItem value={'completion-stats-by-people-leader'}>Stats by people leader</MenuItem>
            <MenuItem value={'mastery-by-component'}>Mastery levels met by region per component</MenuItem>
            <MenuItem value={'mastery-by-capability'}>Mastery levels met by region per category/capability</MenuItem>
          </Select>
        )}
      </TableCell>
    </TableRow>
  );
};

export default AssessmentRoundRow;
