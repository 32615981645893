import { isArray, intersection } from 'lodash';
import * as React from 'react';
import { Me, UserRole } from '../graphql';
import Unauthorised from '../modules/pages/Unauthorised';
import { meQuery } from '../modules/users/queries';
import { RouteHelper } from '../routes/route-helper';
import { useQuery } from '@apollo/client';

interface Props {
  roles: UserRole | UserRole[];
}

export const hasAnyRole = (roles: UserRole[], targetRoles: UserRole[]) => intersection(roles, targetRoles).length > 0;

const Authorised: React.FC<Props> = ({ roles, children }) => {
  const { data, loading } = useQuery<Me>(meQuery);

  if (loading) {
    return null;
  }

  const r = new RouteHelper(Unauthorised, data && data.me);
  const rolesArray: UserRole[] = isArray(roles) ? roles : [roles];
  return r.hasAnyRole(...rolesArray) ? <React.Fragment>{children}</React.Fragment> : null;
};

export default Authorised;
