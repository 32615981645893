import { FormControlLabel, Grid, makeStyles, Typography } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';
import { Field } from 'react-final-form';
import RadioField from './form/RadioField';
import { LevelOfMasteryInfo } from './levelsOfMasteryInfo';

const useStyles = makeStyles(theme => ({
  tooltipText: {
    marginTop: 8,
    fontSize: 13,
  },
  radioText: {
    color: theme.palette.secondary.main,
    fontSize: 13,
  },
  gridItem: {
    textAlign: 'center',
  },
}));

interface Props {
  info: LevelOfMasteryInfo;
  text: string;
  value: string;
  fieldName: string;
  radioTextClass?: string;
}

const CapabilityRatingResponse: React.FC<Props> = ({ info, text, value, fieldName, radioTextClass }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.gridItem} item xs={6} sm={3}>
      <FormControlLabel
        control={<Field name={fieldName} component={RadioField} type="radio" value={value} />}
        label={<Typography className={cx(classes.radioText, radioTextClass)}>{text}</Typography>}
        labelPlacement="bottom"
      />
    </Grid>
  );
};

export default CapabilityRatingResponse;
