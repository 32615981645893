import { makeStyles, MenuItem, TextField } from '@material-ui/core';
import { groupBy, sortBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { ListRoles } from '../graphql';
import Loading from '../modules/pages/Loading';
import { listRolesQuery } from '../modules/roles/queries';

const useStyles = makeStyles(theme => ({
  roleSelect: {
    marginTop: 8,
    width: 260,
  },
  roleSelectText: {
    color: '#fff !important',
    textAlign: 'left',
  },
  outline: {
    borderColor: '#fff',
  },
}));

interface Props {
  role: string;
  setRole: React.Dispatch<React.SetStateAction<string>>;
}

const RoleSelect: React.FC<Props> = ({ role, setRole }) => {
  const classes = useStyles();
  const { data, loading } = useQuery<ListRoles>(listRolesQuery);
  const [region, setRegion] = useState('');

  const rolesByRegion = useMemo(() => {
    if (!data) {
      return {};
    }
    const roles = sortBy(data!.roles, 'text');
    return groupBy(roles, 'region');
  }, [data]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <TextField
        select
        label="Your region"
        value={region}
        onChange={e => setRegion(e.target.value)}
        margin="dense"
        variant="outlined"
        className={classes.roleSelect}
        InputProps={{
          classes: {
            root: classes.roleSelectText,
            notchedOutline: classes.outline,
          },
        }}
        InputLabelProps={{
          className: classes.roleSelectText,
        }}
      >
        {Object.keys(rolesByRegion).map(r => (
          <MenuItem key={r} value={r}>
            {r}
          </MenuItem>
        ))}
      </TextField>
      {region && (
        <TextField
          select
          label="Your role"
          value={role}
          onChange={e => setRole(e.target.value)}
          margin="dense"
          variant="outlined"
          className={classes.roleSelect}
          InputProps={{
            classes: {
              root: classes.roleSelectText,
              notchedOutline: classes.outline,
            },
          }}
          InputLabelProps={{
            className: classes.roleSelectText,
          }}
        >
          {rolesByRegion[region].map(r => (
            <MenuItem key={r.id} value={r.id}>
              {r.text}
            </MenuItem>
          ))}
        </TextField>
      )}
    </>
  );
};

export default RoleSelect;
