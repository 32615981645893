import { LinearProgress } from '@material-ui/core';
import * as React from 'react';
import { FC, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { usePrevious } from '../hooks/use-previous';
import { Nullable } from '../util/types';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 9999,
    backgroundColor: 'transparent',
    '& > .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: `0 0 10px ${theme.palette.primary.light}, 0 0 5px ${theme.palette.primary.light}`,
    },
  },
  completed: {
    visibility: 'hidden',
    zIndex: -1,
  },
}));

interface Props {
  isLoading?: boolean;
}

const ProgressBar: FC<Props> = ({ isLoading }) => {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);
  const oldLoadingState = usePrevious<boolean | undefined>(isLoading);
  const [completed, setCompleted] = useState(false);
  const progressInterval = useRef<Nullable<NodeJS.Timeout>>(null);
  const completedTimeout = useRef<Nullable<NodeJS.Timeout>>(null);

  useEffect(() => {
    if (oldLoadingState && !isLoading) {
      if (progressInterval.current) {
        clearInterval(progressInterval.current);
      }
      setProgress(100);
      completedTimeout.current = setTimeout(() => setCompleted(true), 500);
    } else if (!oldLoadingState && isLoading) {
      setProgress(20);
      setCompleted(false);
      progressInterval.current = setInterval(() => {
        setProgress(oldProgress => {
          // progressively build up to 99 and then hold there until loading is finished
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 99);
        });
      }, 300);
    }
    return () => {
      if (completedTimeout.current) {
        clearInterval(completedTimeout.current);
      }
      if (progressInterval.current) {
        clearInterval(progressInterval.current);
      }
    };
  }, [isLoading, oldLoadingState]);

  return (
    <LinearProgress
      variant="determinate"
      value={progress}
      color="primary"
      className={cx(classes.root, { [classes.completed]: completed })}
    />
  );
};

export default ProgressBar;
