import { gql } from '@apollo/client';

export const listCapabilitiesQuery = gql`
  query ListCapabilities {
    capabilities {
      id
      name
      description
      category
      order
      components {
        id
        text
        emerging
        proficient
        expert
      }
    }
  }
`;

export const listCapabilitySummariesQuery = gql`
  query ListCapabilitySummaries {
    capabilities {
      id
      name
      category
    }
  }
`;

export const createCapabilityMutation = gql`
  mutation CreateCapability($capability: CreateCapabilityInput!) {
    createCapability(capability: $capability)
  }
`;

export const updateCapabilityMutation = gql`
  mutation UpdateCapability($capability: UpdateCapabilityInput!) {
    updateCapability(capability: $capability)
  }
`;

export const archiveCapabilityMutation = gql`
  mutation ArchiveCapability($capabilityId: ID!) {
    archiveCapability(capabilityId: $capabilityId)
  }
`;
