import {Backdrop, makeStyles} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff"
    },
    message: {
        fontWeight: 400
    }
}));

const LoadingMask: React.FC<{ message?: string }> = ({message}) => {
    const classes = useStyles();
    return <Backdrop className={classes.backdrop} open={true}>
        <div>
            <CircularProgress color="inherit" />
            {message && <h3 className={classes.message}>{message}</h3>}
        </div>
    </Backdrop>
};

export default LoadingMask;
