import {
  FormControlLabel,
  makeStyles,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import classNames from 'classnames';
import { includes } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import HeaderWithTitle from '../../components/HeaderWithTitle';
import { showErrorMessage, showMessage } from '../../components/Toast';
import { ListUsers, ListUsers_users, UserRole } from '../../graphql';
import { requestJSON } from '../../util/http';
import InviteUserDialog from './InviteUserDialog';
import { listUsersQuery } from './queries';
import { roleAsText } from './RolesField';
import { useQuery } from '@apollo/client';

const reinviteUser = async (userId: string) => {
  await requestJSON('/auth/re-invite', 'post', { userId })
    .then(inviteResponse => showMessage('Invitation email has been resent'))
    .catch(() => showErrorMessage('Error reinviting the user.'));
};

const isSuper = (user: ListUsers_users) => includes(user.roles, UserRole.super);

const isDisabled = (user: ListUsers_users) => !user.enabled && !user.invited;

const List: React.FC = () => {
  const [showAllUsers, setShowAllUsers] = useState(false);
  const { data } = useQuery<ListUsers>(listUsersQuery);

  const allUsers = (data && data.users) || [];
  const filteredUsers = allUsers.filter(user => !isDisabled(user));

  console.log(setShowAllUsers);
  return (
    <>
      <HeaderWithTitle
        actions={
          <>
            {' '}
            <FormControlLabel
              control={
                <Switch checked={showAllUsers} onChange={() => setShowAllUsers(!showAllUsers)} color="primary" />
              }
              label="Show disabled"
            />
            <InviteUserDialog />
          </>
        }
        title="Users"
      />

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Roles</TableCell>
            <TableCell>State</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <UsersRows users={showAllUsers ? allUsers : filteredUsers} />
        </TableBody>
      </Table>
    </>
  );
};

const useStylesRows = makeStyles(theme => ({
  disabledUserRow: {
    '& td': {
      color: theme.palette.text.secondary,
    },
  },
  usernameCell: {
    display: 'flex',
  },
  avatar: {
    height: 16,
    width: 16,
    marginRight: theme.spacing(2),
  },
}));

interface UsersRowsProps {
  users: ListUsers_users[];
}
const UsersRows = ({ users }: UsersRowsProps) => {
  const classes = useStylesRows();
  return (
    <>
      {users.map(user => (
        <TableRow
          key={user.id}
          className={classNames({
            [classes.disabledUserRow]: isDisabled(user),
          })}
        >
          <TableCell>
            <div className={classes.usernameCell}>
              <div>{user.name}</div>
            </div>
          </TableCell>
          <TableCell>{user.email}</TableCell>
          <TableCell>{user.roles.map(roleAsText).join(', ')}</TableCell>
          <TableCell>
            {user.invited ? (
              <span>
                Invited {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" onClick={() => reinviteUser(user.id)}>
                  (Reinvite)
                </a>
              </span>
            ) : isDisabled(user) ? (
              'Disabled'
            ) : (
              'Active'
            )}
          </TableCell>
          <TableCell>{!isSuper(user) && user.enabled && <Link to={`/admin/users/${user.id}`}>Edit</Link>}</TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default List;
