import * as React from 'react';
import { useState } from 'react';
import { UserRole } from '../../graphql';
import { RouteHelper } from '../../routes/route-helper';
import PageTitle from '../PageTitle';
import AdminSidebar from './AdminSidebar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as 'row',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
}));

interface Props {
  r: RouteHelper;
  anyRoles: UserRole[];
}

const AdminLayout: React.FC<Props> = ({ children, r, anyRoles }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  if (!r.hasAnyRole(...anyRoles)) {
    return r.defaultElement();
  }

  return (
    <div>
      <PageTitle open={open} handleDrawerOpen={() => setOpen(true)} showDrawer={true} r={r} />
      <main className={classes.root}>
        <AdminSidebar open={open} handleDrawerClose={() => setOpen(false)} />
        <div className={classes.content}>
          <div className={classes.toolbar} />
          <div>{children}</div>
        </div>
      </main>
    </div>
  );
};

export default AdminLayout;
