import { CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import * as React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& :first-child': {
      marginBottom: theme.spacing(2),
    },
  },
  page: {
    height: '100vh',
  },
  inline: {
    marginTop: theme.spacing(4),
  },
}));

interface Props {
  message?: string;
  inline?: boolean;
}

const Loading: React.FC<Props> = ({ message, inline = false }) => {
  const classes = useStyles();
  return (
    <div
      className={cx(classes.root, {
        [classes.page]: !inline,
        [classes.inline]: inline,
      })}
    >
      <CircularProgress />
      <Typography>{message ? message : 'Loading...'}</Typography>
    </div>
  );
};

export default Loading;
