import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { toPercentage } from '../../util/util';
import { AssessmentRoundMasteryByComponents_assessmentRoundMasteryByComponents_capabilities_components_regionStats } from '../../graphql';

interface Props {
  regionStats: AssessmentRoundMasteryByComponents_assessmentRoundMasteryByComponents_capabilities_components_regionStats[];
}

const useStyles = makeStyles(theme => ({
  statisticHeading: {
    display: 'inline-block',
    width: 110,
  },
  statisticData: {
    display: 'inline-block',
    width: 40,
    textAlign: 'right',
  },
  statisticDataRight: {
    marginLeft: theme.spacing(6),
  },
  statisticsTotal: {
    fontSize: '1.3rem',
  },
}));

const CompletionByRegions: React.FC<Props> = ({ regionStats }) => {
  const classes = useStyles();
  return (
    <ul className="list-unstyled">
      {regionStats.map(region => {
        const { regionName, masteryLevelMetCount, completedAssessments } = region;
        return (
          <li key={regionName}>
            <span className={classes.statisticHeading}>{regionName}:</span>
            <span className={classes.statisticData}>
              <Typography className={classes.statisticsTotal} variant="body1">
                {toPercentage(completedAssessments > 0 ? masteryLevelMetCount / completedAssessments : 0)}
              </Typography>
            </span>
            <span className={classes.statisticDataRight}>
              <Typography variant="caption">
                {masteryLevelMetCount} / {completedAssessments}
              </Typography>
            </span>
          </li>
        );
      })}
    </ul>
  );
};

export default CompletionByRegions;
