import React from "react";
import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    nextButton: {
        width: 200,
        marginTop: 16,
    },
    backButton: {
        color: theme.palette.secondary.main,
        width: 200,
        marginTop: 8,
    }
}));


const CapabilityActionButtons: React.FC<{ step: number, steps: number, onClickNext: any, onClickPrev: any }> = ({step, steps, onClickNext, onClickPrev}) => {
    const classes = useStyles();
    return <>
        {step === steps && (
            <div>
                <Button type="submit" variant="contained" color="primary"
                        className={classes.nextButton}>
                    Submit
                </Button>
            </div>
        )}
        {step < steps && (
            <div>
                <Button
                    onClick={onClickNext}
                    variant="contained"
                    color="primary"
                    className={classes.nextButton}
                >
                    Next
                </Button>
            </div>
        )}
        {step > 1 && (
            <div>
                <Button onClick={onClickPrev} className={classes.backButton}>
                    Back
                </Button>
            </div>
        )}
    </>
}

export default CapabilityActionButtons
