import { Button, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import Form from '../../components/Form';
import Input from '../../components/form/TextField';
import { UpdateUser, UpdateUserVariables, UserDetails, UserDetailsVariables, UserRole } from '../../graphql';
import { required } from '../../util/validation';
import { userDetailsQuery } from './queries';
import RolesField from './RolesField';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useHistory, useRouteMatch } from 'react-router-dom';

const mutation = gql`
  mutation UpdateUser($userId: ID!, $name: String!, $roles: [UserRole!]!) {
    updateUser(id: $userId, name: $name, roles: $roles) {
      id
      name
      email
      roles
    }
  }
`;

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 300,
  },
}));

interface RouteProps {
  userId: string;
}

interface FormProps {
  name: string;
  roles: UserRole[];
}

const UpdateUserPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { params } = useRouteMatch<RouteProps>();
  const { userId } = params;

  const { data, loading } = useQuery<UserDetails, UserDetailsVariables>(userDetailsQuery, {
    variables: { userId },
  });

  const [updateUser] = useMutation<UpdateUser, UpdateUserVariables>(mutation);

  if (loading && (!data || !data.userById)) {
    return <CircularProgress />;
  }

  if (!data || !data.userById) {
    return <div>User not found</div>;
  }

  const user = data.userById;

  return (
    <>
      <Typography variant="h6" paragraph>
        {user.name}
      </Typography>

      <Typography variant="h5">Login</Typography>

      <Typography variant="body1" paragraph>
        Email: {user.email}
      </Typography>

      <Typography variant="h5">Update</Typography>
      <Form<FormProps>
        onSubmit={({ name, roles }) =>
          updateUser({
            variables: { userId, name, roles },
          })
        }
        initialValues={{
          name: user.name,
          roles: user.roles,
        }}
        successMessage="Updated user"
        onSuccess={() => history.push(`/admin/users`)}
      >
        {({ handleSubmit }: FormRenderProps) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Field
              label="Name"
              name="name"
              margin="normal"
              fullWidth
              validate={required('Name is required')}
              component={Input}
            />

            <RolesField />

            <Button variant="contained" color="primary" type="submit">
              Save changes
            </Button>
          </form>
        )}
      </Form>
    </>
  );
};
export default UpdateUserPage;
