import { Button, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import MarketingMastersLogo from '../assets/marketing-masters.png';
import {
  ListCapabilitySummaries,
  StartAssessment,
  StartAssessmentVariables,
  Status,
  ValidateInvite,
  ValidateInviteVariables,
} from '../graphql';
import { listCapabilitySummariesQuery } from '../modules/capabilities/queries';
import Loading from '../modules/pages/Loading';
import RoleSelect from './RoleSelect';
import { useHistory } from 'react-router-dom';

const validateInviteGql = gql`
  query ValidateInvite($id: ID!) {
    validateInvite(id: $id) {
      valid
      firstName
      status
    }
  }
`;

const startAssessmentGql = gql`
  mutation StartAssessment($id: ID!, $roleId: String!) {
    startAssessment(id: $id, roleId: $roleId)
  }
`;

const useStyles = makeStyles(_ => ({
  image: {
    marginTop: 16,
    width: 200,
  },
  text: {
    color: '#fff',
    fontSize: 13,
  },
  button: {
    marginTop: 6,
    width: 260,
  },
  highlight: {
    backgroundColor: '#da9c07',
    borderRadius: 15,
    padding: 10,
    marginTop: 5,
    marginBottom: 9,
  },
}));

const Welcome: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const history = useHistory();
  const classes = useStyles();
  const [role, setRole] = useState('');
  const { id } = match.params;
  const { data, loading } = useQuery<ValidateInvite, ValidateInviteVariables>(validateInviteGql, {
    variables: { id },
  });
  const { data: caps, loading: capsLoading } = useQuery<ListCapabilitySummaries>(listCapabilitySummariesQuery);
  const [startAssessment] = useMutation<StartAssessment, StartAssessmentVariables>(startAssessmentGql);

  if (loading || capsLoading) {
    return <Loading />;
  }

  const start = () => {
    startAssessment({ variables: { id, roleId: role } });
    history.replace(`/${id}/levels`);
    window.scrollTo(0, 0);
  };

  const validation = data!.validateInvite;

  if (validation && validation.valid && validation.status === Status.IN_PROGRESS) {
    return <Redirect to={`/${id}/caps`} />;
  }

  const capabilityCount = caps && caps.capabilities ? caps.capabilities.length : 0;

  return (
    <div className="welcome">
      <div className="main-content">
        <img src={MarketingMastersLogo} alt="" className={classes.image} />

        {validation === null || !validation.valid ? (
          <>
            <h3>Access link invalid</h3>
            {validation && validation.status === Status.ARCHIVED ? (
              <div className={classes.text}>The access link you are using is no longer valid.</div>
            ) : (
              <div className={classes.text}>
                The access link you are using is invalid or you have already completed your Marketing Masters My Mastery
                Review.
              </div>
            )}
          </>
        ) : (
          <>
            <h3>Marketing Masters My Mastery Review for {validation.firstName}</h3>
            <div className={classes.text}>
              <p>
                The purpose of My Mastery Review is to help you identify your strengths and growth opportunities across{' '}
                {capabilityCount} areas of marketing capability.
              </p>
              <p>
                For each capability component, you’ll be asked to identify your current level of Mastery from (1)
                Emerging, (2) Proficient, (3) Expert to (4) Visionary. These are defined per capability component.
              </p>
              <p>
                It should take you no more than one hour to complete. It’s important that you answer all questions
                honestly.
              </p>
              <p>
                Your progress will be saved and you can use the link from your invitation email to resume at any time.
                Upon completion, you’ll receive an email with your results. The PDF report will be automatically emailed
                to your People Leader. If you have any questions, please speak with your People Leader.
              </p>
              <div className={classes.highlight}>
                If you'd like a copy of your FY22 My Mastery Review to help you complete your FY23 My Mastery Review,
                just email us at <a href="mailto:marketing.masters@anz.com">marketing.masters@anz.com</a>. You might
                find this particularly helpful if some of your mastery levels have stayed the same.
              </div>
            </div>
            <div>
              <RoleSelect role={role} setRole={setRole} />
            </div>

            {role && (
              <Button onClick={() => role && start()} variant="contained" color="primary" className={classes.button}>
                Get started
              </Button>
            )}

            <div className={classes.text} style={{ marginTop: 16 }}>
              Note: My Mastery Review works best on desktop Chrome so we recommend completing it using your ANZ laptop.
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Welcome;
