import { makeStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import React from 'react';
import MarketingMastersLogo from '../assets/marketing-masters.png';

const useStyles = makeStyles(theme => ({
  root: {
    background: 'radial-gradient(678.50px at 50% 0%, #00517E 0%, #002236 100%);',
    color: '#072760',
    boxShadow: 'solid--+++',
    fontWeight: 500,
    fontSize: 14,
  },
  header: {
    flexGrow: 1,
  },
  logo: {
    height: 90,
    padding: '12px 0px',
    flexGrow: 1,
  },
}));

const Header = () => {
  const classes = useStyles();
  return (
    <AppBar position="fixed" className={classes.root}>
      <Toolbar>
        <div className={classes.header}>
          <img className={classes.logo} src={MarketingMastersLogo} alt="ANZ Logo" />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
