/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAssessment
// ====================================================

export interface GetAssessment_assessment_componentRatings {
  __typename: "ComponentRating";
  id: string;
  level: number;
}

export interface GetAssessment_assessment_capabilityComments {
  __typename: "CapabilityComment";
  id: string;
  comment: string;
}

export interface GetAssessment_assessment {
  __typename: "AssessmentProgress";
  componentRatings: GetAssessment_assessment_componentRatings[];
  capabilityComments: GetAssessment_assessment_capabilityComments[];
}

export interface GetAssessment {
  assessment: GetAssessment_assessment;
}

export interface GetAssessmentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveAssessment
// ====================================================

export interface SaveAssessment {
  saveAssessment: string;
}

export interface SaveAssessmentVariables {
  id: string;
  componentRatings: ComponentRatingInput[];
  capabilityComments: CapabilityCommentInput[];
  complete: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ValidateInvite
// ====================================================

export interface ValidateInvite_validateInvite {
  __typename: "ValidateInviteResponse";
  valid: boolean;
  firstName: string;
  status: Status;
}

export interface ValidateInvite {
  validateInvite: ValidateInvite_validateInvite | null;
}

export interface ValidateInviteVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: StartAssessment
// ====================================================

export interface StartAssessment {
  startAssessment: string;
}

export interface StartAssessmentVariables {
  id: string;
  roleId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckActivationCode
// ====================================================

export interface CheckActivationCode {
  checkActivationCode: string | null;
}

export interface CheckActivationCodeVariables {
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConfirmResetPassword
// ====================================================

export interface ConfirmResetPassword {
  confirmResetPassword: boolean | null;
}

export interface ConfirmResetPasswordVariables {
  password: string;
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResetPassword
// ====================================================

export interface ResetPassword {
  resetPassword: boolean | null;
}

export interface ResetPasswordVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListActivities
// ====================================================

export interface ListActivities_activities {
  __typename: "Activity";
  id: string;
  description: string;
  type: ActivityType;
  capabilityIds: string[];
  createdAt: any;
}

export interface ListActivities {
  activities: ListActivities_activities[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateActivity
// ====================================================

export interface CreateActivity {
  createActivity: string;
}

export interface CreateActivityVariables {
  activity: CreateActivityInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateActivity
// ====================================================

export interface UpdateActivity {
  updateActivity: string;
}

export interface UpdateActivityVariables {
  activity: UpdateActivityInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteActivity
// ====================================================

export interface DeleteActivity {
  deleteActivity: string;
}

export interface DeleteActivityVariables {
  activityId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListAssessmentRounds
// ====================================================

export interface ListAssessmentRounds_assessmentRounds {
  __typename: "AssessmentRound";
  id: string;
  createdAt: any;
  lastInvitedAt: any;
}

export interface ListAssessmentRounds {
  assessmentRounds: ListAssessmentRounds_assessmentRounds[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AssessmentRoundStatistics
// ====================================================

export interface AssessmentRoundStatistics_assessmentRoundStatistics_invited {
  __typename: "AssessmentStatusStatistics";
  status: Status | null;
  total: number;
  percentageInRound: number;
}

export interface AssessmentRoundStatistics_assessmentRoundStatistics_inProgress {
  __typename: "AssessmentStatusStatistics";
  status: Status | null;
  total: number;
  percentageInRound: number;
}

export interface AssessmentRoundStatistics_assessmentRoundStatistics_complete {
  __typename: "AssessmentStatusStatistics";
  status: Status | null;
  total: number;
  percentageInRound: number;
}

export interface AssessmentRoundStatistics_assessmentRoundStatistics {
  __typename: "AssessmentRoundStatistics";
  total: number;
  roundName: string;
  invited: AssessmentRoundStatistics_assessmentRoundStatistics_invited;
  inProgress: AssessmentRoundStatistics_assessmentRoundStatistics_inProgress;
  complete: AssessmentRoundStatistics_assessmentRoundStatistics_complete;
}

export interface AssessmentRoundStatistics {
  assessmentRoundStatistics: AssessmentRoundStatistics_assessmentRoundStatistics;
}

export interface AssessmentRoundStatisticsVariables {
  roundName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AssessmentRoundStatisticsByRoles
// ====================================================

export interface AssessmentRoundStatisticsByRoles_assessmentRoundStatisticsByRoles_roles {
  __typename: "AssessmentRoundStatisticsByRole";
  role: string;
  region: string;
  inProgress: number;
  complete: number;
  archived: boolean;
}

export interface AssessmentRoundStatisticsByRoles_assessmentRoundStatisticsByRoles {
  __typename: "AssessmentRoundStatisticsByRoles";
  roundName: string;
  roles: AssessmentRoundStatisticsByRoles_assessmentRoundStatisticsByRoles_roles[];
}

export interface AssessmentRoundStatisticsByRoles {
  assessmentRoundStatisticsByRoles: AssessmentRoundStatisticsByRoles_assessmentRoundStatisticsByRoles;
}

export interface AssessmentRoundStatisticsByRolesVariables {
  roundName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AssessmentRoundStatisticsByManagers
// ====================================================

export interface AssessmentRoundStatisticsByManagers_assessmentRoundStatisticsByManagers_managers_manager {
  __typename: "User";
  email: string;
  name: string | null;
}

export interface AssessmentRoundStatisticsByManagers_assessmentRoundStatisticsByManagers_managers {
  __typename: "AssessmentRoundStatisticsByManager";
  manager: AssessmentRoundStatisticsByManagers_assessmentRoundStatisticsByManagers_managers_manager;
  notStarted: number;
  inProgress: number;
  complete: number;
  total: number;
}

export interface AssessmentRoundStatisticsByManagers_assessmentRoundStatisticsByManagers {
  __typename: "AssessmentRoundStatisticsByManagers";
  roundName: string;
  managers: AssessmentRoundStatisticsByManagers_assessmentRoundStatisticsByManagers_managers[];
}

export interface AssessmentRoundStatisticsByManagers {
  assessmentRoundStatisticsByManagers: AssessmentRoundStatisticsByManagers_assessmentRoundStatisticsByManagers;
}

export interface AssessmentRoundStatisticsByManagersVariables {
  roundName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AssessmentRoundMasteryByComponents
// ====================================================

export interface AssessmentRoundMasteryByComponents_assessmentRoundMasteryByComponents_capabilities_components_regionStats {
  __typename: "AssessmentRoundMasteryByRegion";
  regionName: string;
  masteryLevelMetCount: number;
  completedAssessments: number;
}

export interface AssessmentRoundMasteryByComponents_assessmentRoundMasteryByComponents_capabilities_components {
  __typename: "AssessmentRoundMasteryByComponent";
  text: string;
  regionStats: AssessmentRoundMasteryByComponents_assessmentRoundMasteryByComponents_capabilities_components_regionStats[];
}

export interface AssessmentRoundMasteryByComponents_assessmentRoundMasteryByComponents_capabilities {
  __typename: "AssessmentRoundMasteryByComponentsCapability";
  category: string;
  name: string;
  components: AssessmentRoundMasteryByComponents_assessmentRoundMasteryByComponents_capabilities_components[];
}

export interface AssessmentRoundMasteryByComponents_assessmentRoundMasteryByComponents {
  __typename: "AssessmentRoundMasteryByComponents";
  roundName: string;
  capabilities: AssessmentRoundMasteryByComponents_assessmentRoundMasteryByComponents_capabilities[];
}

export interface AssessmentRoundMasteryByComponents {
  assessmentRoundMasteryByComponents: AssessmentRoundMasteryByComponents_assessmentRoundMasteryByComponents;
}

export interface AssessmentRoundMasteryByComponentsVariables {
  roundName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AssessmentRoundMasteryByCapabilities
// ====================================================

export interface AssessmentRoundMasteryByCapabilities_assessmentRoundMasteryByCapabilities_categories_regionStats {
  __typename: "AssessmentRoundMasteryByRegion";
  regionName: string;
  masteryLevelMetCount: number;
  completedAssessments: number;
}

export interface AssessmentRoundMasteryByCapabilities_assessmentRoundMasteryByCapabilities_categories_capabilities_regionStats {
  __typename: "AssessmentRoundMasteryByRegion";
  regionName: string;
  masteryLevelMetCount: number;
  completedAssessments: number;
}

export interface AssessmentRoundMasteryByCapabilities_assessmentRoundMasteryByCapabilities_categories_capabilities {
  __typename: "AssessmentRoundMasteryByCapability";
  name: string;
  regionStats: AssessmentRoundMasteryByCapabilities_assessmentRoundMasteryByCapabilities_categories_capabilities_regionStats[];
}

export interface AssessmentRoundMasteryByCapabilities_assessmentRoundMasteryByCapabilities_categories {
  __typename: "AssessmentRoundMasteryByCapabilitiesCategory";
  category: string;
  regionStats: AssessmentRoundMasteryByCapabilities_assessmentRoundMasteryByCapabilities_categories_regionStats[];
  capabilities: AssessmentRoundMasteryByCapabilities_assessmentRoundMasteryByCapabilities_categories_capabilities[];
}

export interface AssessmentRoundMasteryByCapabilities_assessmentRoundMasteryByCapabilities {
  __typename: "AssessmentRoundMasteryByCapabilities";
  roundName: string;
  categories: AssessmentRoundMasteryByCapabilities_assessmentRoundMasteryByCapabilities_categories[];
}

export interface AssessmentRoundMasteryByCapabilities {
  assessmentRoundMasteryByCapabilities: AssessmentRoundMasteryByCapabilities_assessmentRoundMasteryByCapabilities;
}

export interface AssessmentRoundMasteryByCapabilitiesVariables {
  roundName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AssessmentRoundParticipants
// ====================================================

export interface AssessmentRoundParticipants_assessmentRoundParticipants_participants_manager {
  __typename: "User";
  email: string;
  name: string | null;
}

export interface AssessmentRoundParticipants_assessmentRoundParticipants_participants {
  __typename: "ParticipantDetails";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  status: Status;
  inviteSent: any;
  startedAt: any | null;
  completedAt: any | null;
  manager: AssessmentRoundParticipants_assessmentRoundParticipants_participants_manager;
  masteryLevelsMetFraction: number | null;
  report: string | null;
}

export interface AssessmentRoundParticipants_assessmentRoundParticipants {
  __typename: "AssessmentParticipants";
  participants: AssessmentRoundParticipants_assessmentRoundParticipants_participants[];
}

export interface AssessmentRoundParticipants {
  assessmentRoundParticipants: AssessmentRoundParticipants_assessmentRoundParticipants;
}

export interface AssessmentRoundParticipantsVariables {
  roundName: string;
  includeArchived?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnlockAssessment
// ====================================================

export interface UnlockAssessment_unlockAssessment_manager {
  __typename: "User";
  email: string;
  name: string | null;
}

export interface UnlockAssessment_unlockAssessment {
  __typename: "ParticipantDetails";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  status: Status;
  inviteSent: any;
  startedAt: any | null;
  completedAt: any | null;
  manager: UnlockAssessment_unlockAssessment_manager;
  masteryLevelsMetFraction: number | null;
  report: string | null;
}

export interface UnlockAssessment {
  unlockAssessment: UnlockAssessment_unlockAssessment;
}

export interface UnlockAssessmentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ArchiveAssessment
// ====================================================

export interface ArchiveAssessment_archiveAssessment_manager {
  __typename: "User";
  email: string;
  name: string | null;
}

export interface ArchiveAssessment_archiveAssessment {
  __typename: "ParticipantDetails";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  status: Status;
  inviteSent: any;
  startedAt: any | null;
  completedAt: any | null;
  manager: ArchiveAssessment_archiveAssessment_manager;
  masteryLevelsMetFraction: number | null;
  report: string | null;
}

export interface ArchiveAssessment {
  archiveAssessment: ArchiveAssessment_archiveAssessment;
}

export interface ArchiveAssessmentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReInvite
// ====================================================

export interface ReInvite {
  reInvite: string;
}

export interface ReInviteVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UploadJob
// ====================================================

export interface UploadJob_uploadJob {
  __typename: "UploadJob";
  id: string;
  status: UploadStatus;
  messages: string[];
}

export interface UploadJob {
  uploadJob: UploadJob_uploadJob;
}

export interface UploadJobVariables {
  uploadJobId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListCapabilities
// ====================================================

export interface ListCapabilities_capabilities_components {
  __typename: "Component";
  id: string;
  text: string;
  emerging: string;
  proficient: string;
  expert: string;
}

export interface ListCapabilities_capabilities {
  __typename: "Capability";
  id: string;
  name: string;
  description: string | null;
  category: string;
  order: number;
  components: ListCapabilities_capabilities_components[];
}

export interface ListCapabilities {
  capabilities: ListCapabilities_capabilities[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListCapabilitySummaries
// ====================================================

export interface ListCapabilitySummaries_capabilities {
  __typename: "Capability";
  id: string;
  name: string;
  category: string;
}

export interface ListCapabilitySummaries {
  capabilities: ListCapabilitySummaries_capabilities[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCapability
// ====================================================

export interface CreateCapability {
  createCapability: string;
}

export interface CreateCapabilityVariables {
  capability: CreateCapabilityInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCapability
// ====================================================

export interface UpdateCapability {
  updateCapability: string;
}

export interface UpdateCapabilityVariables {
  capability: UpdateCapabilityInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ArchiveCapability
// ====================================================

export interface ArchiveCapability {
  archiveCapability: string;
}

export interface ArchiveCapabilityVariables {
  capabilityId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RoleById
// ====================================================

export interface RoleById_roleById_expectedMasteryLevels {
  __typename: "ExpectedMasteryLevel";
  componentId: string;
  level: number;
}

export interface RoleById_roleById {
  __typename: "Role";
  id: string;
  region: string;
  text: string;
  expectedMasteryLevels: RoleById_roleById_expectedMasteryLevels[];
}

export interface RoleById {
  roleById: RoleById_roleById;
}

export interface RoleByIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListRoles
// ====================================================

export interface ListRoles_roles {
  __typename: "Role";
  id: string;
  region: string;
  text: string;
}

export interface ListRoles {
  roles: ListRoles_roles[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListRolesForAdmin
// ====================================================

export interface ListRolesForAdmin_rolesWithMasteryStats_masteryStats {
  __typename: "MasteryStats";
  completedCount: number;
  expectedCount: number;
  completed: boolean;
}

export interface ListRolesForAdmin_rolesWithMasteryStats {
  __typename: "RoleWithMasteryStats";
  id: string;
  region: string;
  text: string;
  masteryStats: ListRolesForAdmin_rolesWithMasteryStats_masteryStats;
}

export interface ListRolesForAdmin {
  rolesWithMasteryStats: ListRolesForAdmin_rolesWithMasteryStats[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateExpectedMasteryLevels
// ====================================================

export interface UpdateExpectedMasteryLevels_updateExpectedMasteryLevels_expectedMasteryLevels {
  __typename: "ExpectedMasteryLevel";
  componentId: string;
  level: number;
}

export interface UpdateExpectedMasteryLevels_updateExpectedMasteryLevels {
  __typename: "Role";
  id: string;
  region: string;
  text: string;
  expectedMasteryLevels: UpdateExpectedMasteryLevels_updateExpectedMasteryLevels_expectedMasteryLevels[];
}

export interface UpdateExpectedMasteryLevels {
  updateExpectedMasteryLevels: UpdateExpectedMasteryLevels_updateExpectedMasteryLevels;
}

export interface UpdateExpectedMasteryLevelsVariables {
  roleId: string;
  expectedMasteryLevels: ExpectedMasteryLevelInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateRole
// ====================================================

export interface CreateRole_createRole {
  __typename: "Role";
  id: string;
}

export interface CreateRole {
  createRole: CreateRole_createRole;
}

export interface CreateRoleVariables {
  region: string;
  text: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateRole
// ====================================================

export interface UpdateRole_updateRole {
  __typename: "Role";
  id: string;
}

export interface UpdateRole {
  updateRole: UpdateRole_updateRole;
}

export interface UpdateRoleVariables {
  role: UpdateRoleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ArchiveRole
// ====================================================

export interface ArchiveRole_archiveRole {
  __typename: "Role";
  id: string;
}

export interface ArchiveRole {
  archiveRole: ArchiveRole_archiveRole;
}

export interface ArchiveRoleVariables {
  roleId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InviteUser
// ====================================================

export interface InviteUser {
  inviteUser: string | null;
}

export interface InviteUserVariables {
  email: string;
  roles: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListUsers
// ====================================================

export interface ListUsers_users_avatar {
  __typename: "Avatar";
  url: string | null;
}

export interface ListUsers_users {
  __typename: "User";
  id: string;
  name: string | null;
  email: string;
  enabled: boolean;
  invited: boolean;
  avatar: ListUsers_users_avatar;
  roles: UserRole[];
}

export interface ListUsers {
  users: ListUsers_users[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserDetails
// ====================================================

export interface UserDetails_userById {
  __typename: "User";
  id: string;
  name: string | null;
  email: string;
  roles: UserRole[];
  enabled: boolean;
  invited: boolean;
}

export interface UserDetails {
  userById: UserDetails_userById | null;
}

export interface UserDetailsVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Me
// ====================================================

export interface Me_me {
  __typename: "User";
  id: string;
  name: string | null;
  roles: UserRole[];
}

export interface Me {
  me: Me_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUser
// ====================================================

export interface UpdateUser_updateUser {
  __typename: "User";
  id: string;
  name: string | null;
  email: string;
  roles: UserRole[];
}

export interface UpdateUser {
  updateUser: UpdateUser_updateUser;
}

export interface UpdateUserVariables {
  userId: string;
  name: string;
  roles: UserRole[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AssessmentStatusStatisticsFragment
// ====================================================

export interface AssessmentStatusStatisticsFragment {
  __typename: "AssessmentStatusStatistics";
  status: Status | null;
  total: number;
  percentageInRound: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: roleFragment
// ====================================================

export interface roleFragment {
  __typename: "Role";
  id: string;
  region: string;
  text: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: roleFragmentWithMasteryLevels
// ====================================================

export interface roleFragmentWithMasteryLevels_expectedMasteryLevels {
  __typename: "ExpectedMasteryLevel";
  componentId: string;
  level: number;
}

export interface roleFragmentWithMasteryLevels {
  __typename: "Role";
  id: string;
  region: string;
  text: string;
  expectedMasteryLevels: roleFragmentWithMasteryLevels_expectedMasteryLevels[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActivityType {
  BRAND_ACADEMY = "BRAND_ACADEMY",
  ON_THE_JOB = "ON_THE_JOB",
  SELF_DIRECTED = "SELF_DIRECTED",
}

export enum Status {
  ARCHIVED = "ARCHIVED",
  COMPLETE = "COMPLETE",
  INVITED = "INVITED",
  IN_PROGRESS = "IN_PROGRESS",
}

export enum UploadStatus {
  complete = "complete",
  failed = "failed",
  started = "started",
}

export enum UserRole {
  admin = "admin",
  people_leader = "people_leader",
  super = "super",
}

export interface CapabilityCommentInput {
  id: string;
  comment: string;
}

export interface ComponentRatingInput {
  id: string;
  level: number;
}

export interface CreateActivityInput {
  description: string;
  type: string;
  capabilityIds: string[];
}

export interface CreateCapabilityInput {
  name: string;
  category: string;
  description?: string | null;
  order: number;
  components: CreateComponentInput[];
}

export interface CreateComponentInput {
  text: string;
  emerging: string;
  proficient: string;
  expert: string;
}

export interface ExpectedMasteryLevelInput {
  componentId: string;
  level: number;
}

export interface UpdatableComponentInput {
  id?: string | null;
  text: string;
  emerging: string;
  proficient: string;
  expert: string;
}

export interface UpdateActivityInput {
  id: string;
  description: string;
  type: string;
  capabilityIds: string[];
}

export interface UpdateCapabilityInput {
  id: string;
  name: string;
  category: string;
  description?: string | null;
  order: number;
  components: UpdatableComponentInput[];
}

export interface UpdateRoleInput {
  id: string;
  region: string;
  text: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
