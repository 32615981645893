import { FormHelperText, InputLabel } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import RichTextEditor, { EditorValue } from 'react-rte';
import { FieldRenderProps } from 'react-final-form';

const toolbarConfig = {
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
    { label: 'Italic', style: 'ITALIC' },
  ],
  BLOCK_TYPE_DROPDOWN: [],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
  ],
};

const EditorField: React.FC<FieldRenderProps<string>> = ({
  input: { onChange: inputOnChange, value: inputValue },
  meta,
  label,
  readOnly,
}) => {
  const editorValue = inputValue
    ? RichTextEditor.createValueFromString(inputValue, 'html')
    : RichTextEditor.createEmptyValue();
  const [value, setValue] = useState<EditorValue>(editorValue);

  useEffect(() => {
    const el = document.querySelector("button[title='Image']");
    if (el) {
      (el as any).style.cssText = 'display: none';
    }
  }, []);

  const onChange = useCallback(
    (value: EditorValue) => {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      inputOnChange(value.toString('html'));
      setValue(value);
    },
    [inputOnChange],
  );

  return (
    <div style={{ flexDirection: 'row', flex: 1 }}>
      <InputLabel shrink style={{ marginTop: 4, marginBottom: 4 }}>
        {label}
      </InputLabel>
      <div className="textEditor">
        <RichTextEditor
          value={value}
          onChange={onChange}
          editorStyle={{ height: 180 }}
          readOnly={readOnly}
          // @ts-ignore
          toolbarConfig={toolbarConfig}
        />
      </div>
      {meta.error && meta.touched && <FormHelperText error={true}>{meta.error || meta.submitError}</FormHelperText>}
    </div>
  );
};

export default EditorField;
